import {
  SolAvatar,
  SolButton,
  SolIcon,
  SolIconBubble,
  SolProgressBar,
} from "@solstice/sol-react";
import { Modal } from "ui-molecules";

interface IPublishModal {
  isOpen: boolean;
  setIsOpen: any;
  data?: {
    photo: string;
    label: string;
    title: string;
  };
}

const PublishModal: React.FC<IPublishModal> = ({ isOpen, setIsOpen, data }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="medium">
      <Modal.Header>
        <div className="flex flex-row justify-between items-start">
          <SolIconBubble icon="publish" size="56" />
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col my-4">
          <div className="mb-6">
            <h4 className="text-xl mb-1">Publish to all consumers</h4>
            <p>OLM, Invester Center, AEM/People Finder and Deal X</p>
          </div>

          <div className="flex flex-row items-center space-x-4 mb-12">
            <SolAvatar image={data?.photo} name={data?.label} size="56" />
            <div>
              <p>{data?.label}</p>
              <p className="text-jll-text-base-subdued">
                {data?.title}
              </p>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex flex-col items-center mb-4">
              <p className="text-jll-text-base-subdued text-sm">
                Publishing in progress
              </p>
              <p className="text-3xl">50%</p>
            </div>
            <SolProgressBar progress={50} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row w-full justify-end">
          <SolButton
            className="!w-[96px] sol-w-full"
            onSol-click={() => setIsOpen(false)}
          >
            Cancel
          </SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishModal;
