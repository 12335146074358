import { SolButton, SolIcon, SolTextField } from "@solstice/sol-react";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { patchProfileAPI } from "services";
import { ProfileProps } from "types";
import * as Yup from "yup";

interface SectionBioProps {
  profile: ProfileProps | null;
  setProfile: any;
}

const INITIAL_VALUES = {
  expertise: "",
  experience: "",
  education: "",
  // affiliations: "",
  recognition: "",
  // certified_appraiser: "",
  clients: "",
};

const validationSchema = Yup.object().shape({
  expertise: Yup.string().required("This field is required"),
  experience: Yup.string().required("This field is required"),
  education: Yup.string().required("This field is required"),
  // affiliations: Yup.string().required("This field is required"),
  // recognition: Yup.string().required("This field is required"),
  // certified_appraiser: Yup.string().required("This field is required"),
  // clients: Yup.string().required("This field is required"),
});

const SectionBio: React.FC<SectionBioProps> = ({ profile, setProfile }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [patchProfile] = useApiCall(patchProfileAPI);
  const isDisabledGenerate =
    !profile?.experience &&
    !profile?.expertise &&
    !profile?.education &&
    !profile?.affiliations &&
    !profile?.recognition &&
    // !profile?.certified_appraiser &&
    !profile?.clients;

  useEffect(() => {
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: (profile as any)?.[key] || "",
      };
    });
    setValues({
      ...formValues,
    });
  }, [profile]);

  const { handleBlur, setFieldValue, values, errors, touched, setValues } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

  const handleUpdate = (key: string, value: any) => {
    try {
      patchProfile({
        pk: profile?.id,
        [key]: value,
      }).then((res: any) => {
        if (!res) return;
        setProfile({
          ...profile,
          [key]: value,
        });
      });
    } catch (err) {}
  };

  return (
    <>
      <p className="text-sm text-jll-text-base-subdued mb-4">
        These fields will not be displayed on your bio, but are used for JLL GPT
      </p>
      <div className="py-6 px-5 border border-jll-stroke-default-1 rounded">
        <div className="mb-8">
          <SolTextField
            label="Expertise/Specialties"
            required
            size="small"
            value={values.expertise}
            onSol-input={(e: any) => setFieldValue("expertise", e?.detail)}
            id="expertise"
            onBlur={(e) => {
              handleBlur(e);
              handleUpdate("expertise", values.expertise);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUpdate("expertise", values.expertise);
              }
            }}
            errorLabel={touched?.expertise ? errors?.expertise : ""}
          />
        </div>
        <div className="mb-8">
          <SolTextField
            label="Experience"
            required
            className="mb-12"
            size="small"
            value={values.experience}
            onSol-input={(e: any) => setFieldValue("experience", e?.detail)}
            id="experience"
            onBlur={(e) => {
              handleBlur(e);
              handleUpdate("experience", values.experience);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUpdate("experience", values.experience);
              }
            }}
            errorLabel={touched?.experience ? errors?.experience : ""}
          />
        </div>
        <div className="mb-8">
          <SolTextField
            label="Education"
            required
            className="mb-12"
            size="small"
            value={values.education}
            onSol-input={(e: any) => setFieldValue("education", e?.detail)}
            id="education"
            onBlur={(e) => {
              handleBlur(e);
              handleUpdate("education", values.education);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUpdate("education", values.education);
              }
            }}
            errorLabel={touched?.education ? errors?.education : ""}
          />
        </div>
        {/* <div className="mb-8">
          <SolTextField
            label="Affiliations"
            required
            className="mb-12"
            size="small"
            value={values.affiliations}
            onSol-input={(e: any) => setFieldValue("affiliations", e?.detail)}
            id="affiliations"
            onBlur={(e) => {
              handleBlur(e);
              handleUpdate("affiliations", values.affiliations);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUpdate("affiliations", values.affiliations);
              }
            }}
            errorLabel={touched?.affiliations ? errors?.affiliations : ""}
          />
        </div> */}
        <div className="mb-8">
          <SolTextField
            label="Recognition/Awards"
            className="mb-12"
            size="small"
            value={values.recognition}
            onSol-input={(e: any) => setFieldValue("recognition", e?.detail)}
            id="recognition"
            onBlur={(e) => {
              handleBlur(e);
              handleUpdate("recognition", values.recognition);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUpdate("recognition", values.recognition);
              }
            }}
            errorLabel={touched?.recognition ? errors?.recognition : ""}
          />
        </div>
        <div className="">
          <SolTextField
            label="Key clients/Representative clients"
            className="mb-12"
            size="small"
            value={values.clients}
            onSol-input={(e: any) => setFieldValue("clients", e?.detail)}
            id="clients"
            onBlur={(e) => {
              handleBlur(e);
              handleUpdate("clients", values.clients);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUpdate("clients", values.clients);
              }
            }}
            errorLabel={touched?.clients ? errors?.clients : ""}
          />
        </div>
      </div>
      <div className="flex flex-row items-center my-10">
        <SolButton
          variant="secondary"
          onSol-click={() => {
            // isDisabledGenerate ? setActiveTab(3) : setActiveTab(2)
            searchParams.set("mode", "general");
            searchParams.set("active", "tutorial");
            setSearchParams(searchParams);
          }}
        >
          Cancel
        </SolButton>
      </div>
    </>
  );
};

export default SectionBio;
