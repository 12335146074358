import { SolButton, SolIcon } from "@solstice/sol-react";
import {
  Loading,
  LoadingPage,
  StyledListValue,
  StyledListValueProps,
  Table,
} from "ui-molecules";
import LeaderModal from "./LeaderModal";
import { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { BrokerProps, ProfileProps } from "types";
import { getBrokerListAPI, patchProfileAPI } from "services";
import { useApiCall } from "hooks";
import { SEARCH_RESULT_LIMIT, SET_ALERT_MODAL, STATUS_ACTIVE } from "constant";
import useInfiniteScroll from "react-infinite-scroll-hook";
import cn from "classnames";
import { reorder } from "utils";
import { GlobalContext } from "context";
import { Toast } from "ui-atoms";
import { ArrowBackOutlined } from "@material-ui/icons";
import { useSearchParams } from "react-router-dom";

const LEADER_TABLE_COLUMNS: {
  id: StyledListValueProps["valueKey"];
  label: string;
  sort?: string;
}[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "leadership",
    label: "Leadership",
  },
  {
    id: "job_title",
    label: "Job Title",
  },
  {
    id: "city_str",
    label: "City",
  },
  {
    id: "country",
    label: "Country",
  },
  {
    id: "language_id",
    label: "Language",
  },
];

interface LeaderProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
  broker: BrokerProps | null;
}

const Leader: React.FC<LeaderProps> = ({
  profile,
  setProfile,
  isLoading,
  broker,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [patchProfile] = useApiCall(patchProfileAPI);
  const [getBrokerList, isBrokerLoading] = useApiCall(getBrokerListAPI);
  const [data, setData] = useState<BrokerProps[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const isDragRef = useRef(false);

  useEffect(() => {
    if (isDragRef.current) {
      isDragRef.current = false;
      return;
    }
    if (!profile?.related_profiles?.length) {
      setData([]);
      return;
    }
    setIsFirstLoading(true);
    getBrokerList({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      // active_status: [STATUS_ACTIVE],
      broker_profile: profile?.related_profiles,
      sort: "update_timestamp",
      direction: "desc",
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      })
      ?.finally(() => {
        setIsFirstLoading(false);
      });
  }, [profile]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getBrokerList({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      broker_profile: profile?.related_profiles,
      sort: "update_timestamp",
      direction: "desc",
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isBrokerLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const oldOrder = data;
    const oldOrderIds = profile?.related_profiles;
    const newOrderIds = reorder(
      [...(profile?.related_profiles || [])],
      result.source.index,
      result.destination.index
    );

    const newOrder = reorder(
      [...(data || [])],
      result.source.index,
      result.destination.index
    );
    try {
      isDragRef.current = true;
      setProfile({
        ...profile,
        related_profiles: newOrderIds,
      });
      setData(newOrder);
      patchProfile({
        pk: profile?.id,
        related_profiles: newOrderIds,
      }).catch((err: any) => {
        setData(oldOrder);
        setProfile({
          ...profile,
          related_profiles: oldOrderIds,
        });
      });
    } catch (err) {}
  };

  const handleUpdate = (item: BrokerProps) => {
    try {
      const action = () => {
        try {
          isDragRef.current = true;
          let original = [...(profile?.related_profiles || [])];
          original = [...original]?.filter(
            (p_pk: number) => !item?.broker_profile_ids?.includes(p_pk)
          );
          let originalData = [...(data || [])];
          originalData = [...originalData]?.filter(
            (broker: BrokerProps) =>
              broker?.broker_profile_ids?.[0] !== item?.broker_profile_ids?.[0]
          );

          patchProfile({
            pk: profile?.id,
            related_profiles: original,
          }).then((res: any) => {
            if (!res) return;
            setData(originalData);
            setProfile({
              ...profile,
              related_profiles: res?.related_profiles,
            });
            Toast.success(
              `"${[item?.first_name, item?.last_name]?.join(
                " "
              )}"'s profile successfully removed from Leadership`
            );
          });
        } catch (err) {}
      };

      dispatch({
        type: SET_ALERT_MODAL,
        payload: {
          open: true,
          kind: "error",
          title: `Remove from leadership`,
          description: `Are you sure you want to remove "${[
            item?.first_name,
            item?.last_name,
          ]?.join(" ")}" from leadership?`,
          icon: "warning",
          btn3: null,
          btn1: {
            label: "No",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                  btn3: null,
                },
              });
            },
          },
          btn2: {
            label: "Yes",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                  btn3: null,
                },
              });
              action();
            },
          },
        },
      });
    } catch (err) {}
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div className="flex flex-row items-center justify-between mb-12">
            <div>
              <h2 className="text-xl mb-1">Associated leaders profile</h2>
              <p className="text-sm text-jll-text-base-subdued">
                Indicates a JLL leader at corporate, country, or local level.
              </p>
            </div>

            <SolButton variant="secondary" onSol-click={() => setIsOpen(true)}>
              Add leaders <SolIcon icon="add" />
            </SolButton>
          </div>

          <div className="flex flex-col space-y-2 mb-10">
            {isFirstLoading ? (
              <Table.Loading size={4} />
            ) : (
              <>
                {!!data?.length ? (
                  <>
                    <Table>
                      <Table.Thead>
                        <Table.Tr>
                          <Table.Th></Table.Th>
                          {LEADER_TABLE_COLUMNS.map((column, idx) => (
                            <Table.Th key={idx}>{column.label}</Table.Th>
                          ))}
                          <Table.Th>Action</Table.Th>
                        </Table.Tr>
                      </Table.Thead>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <Table.Tbody
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {data?.map((item: any, idx: number) => (
                                <Draggable
                                  key={item?.pk}
                                  draggableId={item?.pk.toString()}
                                  index={idx}
                                >
                                  {(provided, snapshot) => (
                                    <Table.Tr
                                      key={idx}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      className={cn({
                                        "bg-jll-surface-base-secondary flex justify-between items-center":
                                          snapshot.isDragging,
                                      })}
                                    >
                                      <Table.Td>
                                        <div
                                          className="flex items-center justify-center w-5 h-5"
                                          {...provided.dragHandleProps}
                                        >
                                          <SolIcon
                                            icon="menu"
                                            className="text-sm"
                                          />
                                        </div>
                                      </Table.Td>
                                      {LEADER_TABLE_COLUMNS?.map(
                                        (column, idx1) => (
                                          <Table.Td
                                            key={idx1}
                                            className="!whitespace-normal"
                                          >
                                            <StyledListValue
                                              broker={item}
                                              valueKey={column.id}
                                            />
                                          </Table.Td>
                                        )
                                      )}
                                      <Table.Td>
                                        <SolButton
                                          variant="secondary"
                                          onSol-click={() => handleUpdate(item)}
                                          className="sol-w-full"
                                        >
                                          Remove
                                          <SolIcon icon="delete" />
                                        </SolButton>
                                      </Table.Td>
                                    </Table.Tr>
                                  )}
                                </Draggable>
                              ))}
                            </Table.Tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Table>
                    {!!(total > data?.length) && (
                      <div
                        className="w-full py-2 flex justify-center items-center"
                        ref={sentryRef}
                      >
                        <Loading />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-full flex justify-center items-center py-16">
                    <p className="text-center text-jll-text-base-subdued">
                      No associate profiles, click here to add
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "markets");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "bio");
                setSearchParams(searchParams);
              }}
            >
              Next
              <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
        </div>
      )}
      <LeaderModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        profile={profile}
        setProfile={setProfile}
        broker={broker}
      />
    </>
  );
};

export default Leader;
