import { SolButton, SolIcon } from "@solstice/sol-react";
import { ActivityProps, BrokerProps } from "types";
import { Modal, StyledActivityLogValue } from "ui-molecules";
import { capitalize, capitalizeWords, getFormatedDateTime } from "utils";

interface LocationLogModalProps {
  isOpen: boolean;
  setIsOpen: any;
  activity: ActivityProps | null;
}

const LocationLogModal: React.FC<LocationLogModalProps> = ({
  isOpen,
  setIsOpen,
  activity,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="large">
      <Modal.Header>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl">{`${activity?.content_type_id?.label}`}</h2>
            <p>View this location's historical changes</p>
          </div>
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body className="-mx-6 px-6">
        <div className="mt-6 profile-log">
          <div className="flex justify-end mb-4">
            <span className="mr-4">
              {getFormatedDateTime(activity?.update_timestamp)}
            </span>
            <div>
              <span className="text-sm text-jll-text-base-subdued">
                Updated by:
              </span>{" "}
              {[
                activity?.["updated_first_name"],
                activity?.["updated_last_name"],
              ]?.join(" ")}
            </div>
          </div>

          <table className="text-black w-full">
            <tbody>
              {activity &&
                Object.keys(activity)
                  ?.filter((key) => !!key?.includes("change__"))
                  ?.map((key, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="px-4 py-2 bg-jll-surface-base-secondary font-normal w-3/12 align-baseline">
                          {capitalizeWords(key?.replace("change__", ""), "_")}
                        </td>
                        <td className="px-4 py-2 w-full">
                          <StyledActivityLogValue
                            activity={activity}
                            valueKey={key}
                          />
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LocationLogModal;
