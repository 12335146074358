import { PUBLISH_STATUS_PUBLISHED, PUBLISH_STATUS_REMOVED } from "constant";
import { GlobalContext } from "context";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getFormatedDateTime, parseOriginalJSON } from "utils";

interface IStyledActivityLogValue {
  activity: any;
  valueKey: string;
}

const StyledActivityLogValue: React.FC<IStyledActivityLogValue> = ({
  activity,
  valueKey,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;

  switch (valueKey) {
    case "change__photos":
    case "photos":
      return (
        <LazyLoadImage
          src={activity?.[valueKey]?.[0]}
          className="rounded w-12 h-12 object-contain"
          alt="photo"
        />
      );

    case "change__bio":
    case "bio":
      return (
        <div
          className="bio-preview"
          dangerouslySetInnerHTML={{
            __html: parseOriginalJSON(activity?.[valueKey]),
          }}
        />
      );

    case "change__update_timestamp":
    case "update_timestamp":
      if (!activity?.change__update_timestamp) {
        return <>-</>;
      }
      return <>{getFormatedDateTime(activity?.change__update_timestamp)}</>;

    case "change__create_timestamp":
    case "create_timestamp":
      if (!activity?.change__create_timestamp) {
        return <>-</>;
      }
      return <>{getFormatedDateTime(activity?.change__create_timestamp)}</>;

    case "change__publish_status":
    case "publish_status":
      if (activity?.change__publish_status === PUBLISH_STATUS_PUBLISHED)
        return <>Published</>;
      else if (activity?.change__publish_status === PUBLISH_STATUS_REMOVED)
        return <>Removed</>;
      else return <>{activity?.change__publish_status}</>;

    case "change__language":
    case "language":
      const item = meta?.["language"]?.find(
        (val: any) => val?.id?.toString() === activity[valueKey]?.toString()
      );
      if (item) return <>{item?.description || item?.name}</>;
      else return <>{activity[valueKey]}</>;

    default:
      if (activity.hasOwnProperty(valueKey)) {
        if (Array.isArray(activity[valueKey]))
          return (
            <div className="flex flex-col max-w-[80%]">
              {activity[valueKey]?.map((item: string, idx: number) => (
                <span className="text-end truncate">
                  {typeof item === "string" ? item : ""}
                </span>
              ))}
            </div>
          );

        const newKey = valueKey.replace("change__", "");
        const parentKey = Object.keys(meta).find((key: string) => {
          if (typeof meta?.[key] === "object") {
            if (!!meta?.[key]?.[newKey]) return true;
            else return false;
          }
          return false;
        });
        if (!!parentKey)
          return (
            <>
              {meta?.[parentKey]?.[newKey]?.[activity[valueKey]]?.value || "-"}
            </>
          );
        return <>{activity[valueKey]}</>;
      }
      return <>-</>;
  }
};

export default StyledActivityLogValue;
