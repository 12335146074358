import { SolAvatar } from "@solstice/sol-react";
import { ActivityProps, BrokerProps } from "types";
import { getFormatedDateTime } from "utils";
import ActivityContent from "./ActivityContent";

export interface StyledActivityValueProps {
  activity: ActivityProps;
  valueKey: keyof ActivityProps | "updated_user" | "change";
}

const StyledActivityValue: React.FC<StyledActivityValueProps> = ({
  activity,
  valueKey,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "broker":
            return (
              <div className="flex flex-row items-center space-x-3">
                {!!(activity?.object as BrokerProps)?.photos?.length && (
                  <SolAvatar
                    image={(activity?.object as BrokerProps)?.photos?.[0] || ""}
                    size="36"
                  />
                )}
                <div>
                  <p>
                    {[
                      (activity?.object as BrokerProps)?.first_name ||
                        (activity?.object as any)?.firstName,
                      (activity?.object as BrokerProps)?.last_name ||
                        (activity?.object as any)?.lastName,
                    ].join(" ")}
                  </p>
                  <p className="text-sm text-jll-text-base-subdued">
                    {activity?.object?.job_title}
                  </p>
                </div>
              </div>
            );

          case "change":
            return <ActivityContent activity={activity} />;

          case "updated_user":
            return [
              activity?.["updated_first_name"],
              activity?.["updated_last_name"],
            ]?.join(" ");

          case "update_timestamp":
            return getFormatedDateTime(activity?.update_timestamp, {
              dateStyle: "medium",
              timeStyle: "short",
            });

          case "updated":
            return (
              <div>
                <p>
                  {activity?.update_timestamp &&
                    getFormatedDateTime(activity?.update_timestamp, {
                      dateStyle: "medium",
                      timeStyle: "short",
                    })}
                </p>
                {(!!activity?.updated_first_name ||
                  !!activity?.updated_last_name) && (
                  <p className="text-sm text-jll-text-base-default">
                    <span className="text-jll-text-base-subdued">
                      updated by:{" "}
                    </span>
                    {[
                      activity?.updated_first_name,
                      activity?.updated_last_name,
                    ]?.join(" ")}
                  </p>
                )}
              </div>
            );

          case "type_name":
            if (!activity?.type_name) return <>-</>;
            return (
              <div>
                {activity?.content_type_id && (
                  <span className="text-sm text-jll-text-base-subdued capitalize mr-1">
                    {activity?.content_type_id?.label}:
                  </span>
                )}
                <span className="capitalize">{activity?.type_name}</span>
              </div>
            );

          default:
            if (activity?.hasOwnProperty(valueKey)) {
              return <>{(activity as any)?.[valueKey] || ""}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledActivityValue;
