import { LayoutAuthHome } from "ui-organisms";
import { SolButton, SolIcon } from "@solstice/sol-react";
import {
  getDecimalFormating,
  getFormatedDate,
  getOptions,
  joinArray,
} from "utils";
import { SearchOutlined } from "@material-ui/icons";
import queryString from "query-string";
import { useContext, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, SelectAutoComplete, Toast } from "ui-atoms";
import { useApiCall } from "hooks";
import {
  deleteOfficeAPI,
  getCountryAPI,
  getMarketAPI,
  getOfficesAPI,
} from "services";
import {
  OFFICE_TABLE_COLUMNS,
  SEARCH_RESULT_LIMIT,
  SET_ALERT_MODAL,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import { ListLeftSide, Loading, Table } from "ui-molecules";
import { GlobalContext } from "context";
import { FacetsProps, OptionProps } from "types";

const OfficeHome = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { meta } = state;
  const location = useLocation();
  const navigate = useNavigate();
  const [getOffice] = useApiCall(getOfficesAPI);
  const [deleteOffice] = useApiCall(deleteOfficeAPI);
  const [getCountry] = useApiCall(getCountryAPI);
  const [getMarket] = useApiCall(getMarketAPI);
  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [data, setData] = useState<any[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const [total, setTotal] = useState(0);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [facets, setFacets] = useState<FacetsProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const languageOptions = getOptions("language", meta);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    setPage(1);
    if (!paramFilters) return;
    if (!!paramFilters?.language?.length) {
      const selectedLangOptions = languageOptions?.filter(
        (option: OptionProps) =>
          paramFilters?.language?.includes(option?.value?.toString())
      );
      setSelectedOptions((prev: any) => ({
        ...prev,
        language: selectedLangOptions,
      }));
    }
    loadData();
  }, [paramFilters]);

  const loadData = () => {
    setIsFirstLoading(true);
    getOffice({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
        setSortFields(res?.sort_fields);
        setFacets(res?.facets);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      })
      .finally(() => {
        setIsFirstLoading(false);
      });
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setIsLoading(true);
    getOffice({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData((prevData: any) => [...prevData, ...res?.docs]);
        setPage(res?.page);
        setTotal(res?.total);
        setSortFields(res?.sort_fields);
        setFacets(res?.facets);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const handleDelete = (item: any) => {
    const action = () => {
      try {
        deleteOffice(item?.pk).then((res: any) => {
          if (!res?.status) return;
          loadData();
          Toast.success(`Successfully removed "${item?.name}"`);
        });
      } catch (err) {}
    };

    dispatch({
      type: SET_ALERT_MODAL,
      payload: {
        open: true,
        kind: "error",
        title: `Are you sure to remove "${item?.name}"?`,
        description: ``,
        icon: "warning",
        btn3: null,
        btn1: {
          label: "Cancel",
          onClick: () => {
            dispatch({
              type: SET_ALERT_MODAL,
              payload: {
                open: false,
                kind: "",
                title: "",
                description: "",
                icon: "",
                btn1: null,
                btn2: null,
                btn3: null,
              },
            });
          },
        },
        btn2: {
          label: "Confirm",
          onClick: () => {
            dispatch({
              type: SET_ALERT_MODAL,
              payload: {
                open: false,
                kind: "",
                title: "",
                description: "",
                icon: "",
                btn1: null,
                btn2: null,
                btn3: null,
              },
            });
            action();
          },
        },
      },
    });
  };

  const loadCountryOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return Promise.all([
      paramFilters?.country &&
        getCountry({
          ids: paramFilters?.country || [],
          active_status: [STATUS_ACTIVE],
          sort: "name",
          direction: "asc",
        }),
      getCountry({
        keyword,
        page,
        limit: SEARCH_RESULT_LIMIT,
        active_status: [STATUS_ACTIVE],
        sort: "name",
        direction: "asc",
      }),
    ]).then((res_list) => {
      const selected = (res_list?.[0]?.docs || [])?.map((country: any) => {
        return {
          value: country.pk,
          label: country?.name,
        };
      });
      setSelectedOptions((prev: any) => ({ ...prev, country: selected }));
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return {
        options: combinedArray?.map((country: any) => {
          return {
            value: country.pk,
            label: country?.name,
          };
        }),
        hasMore: page < (res_list?.[1] || res_list?.[0])?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  // const loadMarketOptions = async (
  //   keyword: string,
  //   loadedOptions: any,
  //   { page }: any
  // ) => {
  //   return Promise.all([
  //     paramFilters?.market &&
  //       getMarket({
  //         ids: paramFilters?.market || [],
  //         active_status: [STATUS_ACTIVE],
  //         sort: "name",
  //         direction: "asc",
  //       }),
  //     getMarket({
  //       keyword,
  //       page,
  //       limit: SEARCH_RESULT_LIMIT,
  //       active_status: [STATUS_ACTIVE],
  //       sort: "name",
  //       direction: "asc",
  //     }),
  //   ]).then((res_list) => {
  //     const selected = (res_list?.[0]?.docs || [])?.map((market: any) => {
  //       return {
  //         value: market.pk,
  //         label: market?.name,
  //       };
  //     });
  //     setSelectedOptions((prev: any) => ({ ...prev, market: selected }));
  //     const combinedArray = [
  //       ...(res_list?.[0]?.docs || []),
  //       ...res_list?.[1]?.docs.filter(
  //         (obj2: any) =>
  //           !(res_list?.[0]?.docs || []).some(
  //             (obj1: any) => obj1.pk === obj2.pk
  //           )
  //       ),
  //     ];
  //     return {
  //       options: combinedArray?.map((market: any) => {
  //         return {
  //           value: market.pk,
  //           label: market?.name,
  //         };
  //       }),
  //       hasMore: page < (res_list?.[1] || res_list?.[0])?.num_pages,
  //       additional: { page: page + 1 },
  //     };
  //   });
  // };

  const handleFilter = (e: any, key: string) => {
    const updatedFilters = { ...paramFilters };
    if (!e?.length) {
      delete updatedFilters[key];
      setSelectedOptions((prev: any) => ({ ...prev, [key]: [] }));
    } else {
      const values = e?.map((item: any) => item?.value);
      updatedFilters[key] = values;
      setSelectedOptions((prev: any) => ({ ...prev, [key]: e }));
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  return (
    <>
      <LayoutAuthHome>
        <ListLeftSide
          facets={facets}
          clearUrl={URLS.LOCATION.ROOT}
          isLoading={isFirstLoading}
        />
        <div className="flex flex-col w-full h-full relative overflow-hidden">
          {/* Filter */}
          <div className="flex flex-row items-center pt-6 pb-8 mx-10 border-b border-b-jll-stroke-subdued justify-between flex-wrap">
            <div className="flex flex-row items-center space-x-3">
              {paramFilters && (
                <SelectAutoComplete
                  placeholder="Country"
                  className="!mb-0 min-w-[200px] w-full"
                  label="Country"
                  size="sm"
                  isMulti
                  loadOptions={loadCountryOptions}
                  name="country"
                  value={selectedOptions?.country || []}
                  onChange={(e) => {
                    handleFilter(e, "country");
                  }}
                />
              )}
              {paramFilters && (
                // <SelectAutoComplete
                //   placeholder="Market"
                //   className="!mb-0 min-w-[200px] w-full"
                //   label="Market"
                //   size="sm"
                //   isMulti
                //   loadOptions={loadMarketOptions}
                //   name="market"
                //   value={selectedOptions?.market || []}
                //   onChange={(e) => {
                //     handleFilter(e, "market");
                //   }}
                // />

                <SelectAutoComplete
                  name="language"
                  label="Language"
                  className="!mb-0 min-w-[200px] w-full"
                  size="sm"
                  isMulti
                  options={languageOptions}
                  value={selectedOptions?.language}
                  onChange={(e) => {
                    handleFilter(e, "language");
                  }}
                />
              )}
              <div className="mt-8">
                <SolButton
                  variant="tertiary"
                  className="whitespace-nowrap text-jll-text-base-subdued"
                  onSol-click={() => {
                    navigate(URLS.LOCATION.ROOT);
                    setSelectedOptions({});
                  }}
                >
                  Clear filters
                </SolButton>
              </div>
            </div>
            <div>
              <Input
                leadingIcon={SearchOutlined}
                isClearable
                onChange={onChangeInput}
                value={keyword}
                className="w-[350px] !mb-0"
                placeholder="Search by name, country, city..."
                label="Search"
              />
            </div>
          </div>

          {!!data?.length && (
            <div className="flex flex-col mx-10 relative">
              <div className="flex flex-row items-center justify-between space-x-4 py-3 border-b border-b-jll-stroke-subdued">
                <span>{`${getDecimalFormating(total)} result${
                  total > 1 ? "s" : ""
                }`}</span>
              </div>
            </div>
          )}

          <div className="flex flex-1 w-full h-full flex-col px-10 overflow-auto">
            {isFirstLoading ? (
              <Table.Loading avatar={false} />
            ) : (
              <>
                {!!data?.length ? (
                  <>
                    <Table>
                      <Table.Thead>
                        <Table.Tr>
                          {OFFICE_TABLE_COLUMNS?.map((column, idx) => (
                            <Table.Th
                              key={idx}
                              filterId={column.id}
                              sortFields={sortFields}
                              filters={paramFilters}
                              // onClick={onClickSorting}
                            >
                              {column?.label}
                            </Table.Th>
                          ))}
                          <Table.Th>Action</Table.Th>
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {data?.map((item: any, idx: number) => (
                          <Table.Tr key={idx}>
                            {OFFICE_TABLE_COLUMNS?.map((column, idx1) => (
                              <Table.Td
                                key={idx1}
                                className="!whitespace-normal"
                              >
                                {(() => {
                                  switch (column?.id) {
                                    case "update_timestamp":
                                      return getFormatedDate(
                                        item?.[column?.id]
                                      );

                                    case "updated_user":
                                      return (
                                        [
                                          item?.updated_first_name,
                                          item?.updated_last_name,
                                        ].join(" ") || item?.updated_email
                                      );

                                    case "address":
                                      return (
                                        <Table.Td.Clamp
                                          id={`office_${item?.pk}`}
                                        >
                                          {item?.google_address
                                            ?.formatted_address ||
                                            joinArray(
                                              [
                                                item?.street,
                                                item?.city,
                                                item?.state,
                                                item?.country,
                                                item?.google_address
                                                  ?.postal_code,
                                              ],
                                              ", "
                                            )}
                                        </Table.Td.Clamp>
                                      );

                                    case "phone":
                                      return item?.[column?.id]?.replaceAll(
                                        "Tel:",
                                        ""
                                      );

                                    default:
                                      return (
                                        <p className="line-clamp-2">
                                          {item?.[column?.id]}
                                        </p>
                                      );
                                  }
                                })()}
                              </Table.Td>
                            ))}
                            <Table.Td className="space-x-3">
                              <SolButton
                                variant="secondary"
                                onSol-click={() =>
                                  navigate(`${URLS.LOCATION.ROOT}/${item?.pk}`)
                                }
                              >
                                Edit <SolIcon icon="edit" />
                              </SolButton>
                              <SolButton
                                variant="secondary"
                                onSol-click={() => {
                                  handleDelete(item);
                                }}
                              >
                                Delete <SolIcon icon="delete" />
                              </SolButton>
                            </Table.Td>
                          </Table.Tr>
                        ))}
                      </Table.Tbody>
                    </Table>
                    {!!(total > data?.length) && (
                      <div
                        className="w-full py-2 flex justify-center items-center"
                        ref={sentryRef}
                      >
                        <Loading />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-full h-full flex justify-center items-center text-jll-text-base-subdued">
                    There is not any location data
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </LayoutAuthHome>
    </>
  );
};

export default OfficeHome;
