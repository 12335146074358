import AragonStep1Img from "assets/images/aragon_step1.jpeg";
import AragonStep2Img from "assets/images/aragon_step2.jpeg";
import AragonStep3Img from "assets/images/aragon_step3.jpeg";
import AragonStep4Img from "assets/images/aragon_step4.jpeg";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { useApiCall } from "hooks";
import { postProfileAragonAPI } from "services";
import { useParams } from "react-router-dom";
import { BrokerProps } from "types";
import { useContext } from "react";
import { GlobalContext } from "context";
import { SET_ALERT_MODAL } from "constant";
import { renderToString } from "react-dom/server";
import { TutorialStep } from "ui-molecules";

const STEPS = [
  {
    index: 1,
    title: "Sign up and answer questions",
    description: "Create your account and tell us a bit about yourself.",
    imageUrl: AragonStep1Img,
  },
  {
    index: 2,
    title: "Upload your photos",
    description:
      "Choose and upload six of your best photos, replace any that don’t quite fit the bill.",
    imageUrl: AragonStep2Img,
  },
  {
    index: 3,
    title: "Confirm and wait",
    description:
      "Double-check your details, then sit back while Aragon generates your headshots.",
    imageUrl: AragonStep3Img,
  },
  {
    index: 4,
    title: "Review and select",
    description: "Browse your new AI headshots and pick your favorite.",
    imageUrl: AragonStep4Img,
  },
];

interface AragonTutotialProps {
  broker: BrokerProps | null;
}

const AragonTutotial: React.FC<AragonTutotialProps> = ({ broker }) => {
  const { dispatch } = useContext(GlobalContext);
  const { brokerId } = useParams();
  const [postProfileAragon] = useApiCall(postProfileAragonAPI);

  const handleAragon = () => {
    try {
      if (!broker?.ai_images?.length)
        postProfileAragon({ broker: brokerId })?.then((res: any) => {
          if (res?.url) window.open(res?.url, "_blank");
        });
      else {
        dispatch({
          type: SET_ALERT_MODAL,
          payload: {
            open: true,
            kind: "success",
            // description: `You have already received AI generated images. Reach out to BrokerTools@jll.com`,
            html: renderToString(
              <p>
                You have already received AI generated images. Reach out to{" "}
                <a
                  href="mailto:profile.management@jll.com"
                  target="_blank"
                  className="text-jll-stroke-interaction"
                >
                  profile.management@jll.com
                </a>
              </p>
            ),
            icon: "check",
            btn3: null,
            btn1: {
              label: "Cancel",
              onClick: () => {
                dispatch({
                  type: SET_ALERT_MODAL,
                  payload: {
                    open: false,
                    kind: "",
                    title: "",
                    description: "",
                    icon: "",
                    btn1: null,
                    btn2: null,
                    btn3: null,
                  },
                });
              },
            },
          },
        });
      }
    } catch (err) {}
  };

  return (
    <div className="flex flex-col w-full h-full relative overflow-auto px-10">
      <div className="flex flex-col pt-8 mb-12">
        <h3 className="text-xl mb-2">AI Headshots by Aragon</h3>
        <p className="text-sm text-jll-text-base-subdued">
          Welcome to your AI headshot experience! Here’s what you can expect
        </p>
      </div>

      <TutorialStep steps={STEPS} className="mb-12" />

      <div className="mb-[88px]">
        <SolButton onSol-click={handleAragon}>
          Click to proceed <SolIcon icon="open_in_new" />
        </SolButton>
      </div>

      <div className="mb-14">
        <h4 className="text-xl mb-2">Want more details?</h4>
        <p className="text-sm text-jll-text-base-subdued mb-6">
          Click the{" "}
          <span className="text-jll-text-base-default">
            ‘How to guidelines’
          </span>{" "}
          button below for step-by-step instructions.
        </p>
        <SolButton
          variant="secondary"
          onSol-click={() =>
            window.open(
              "https://jll2-my.sharepoint.com/:p:/g/personal/briggs_thompson_am_jll_com/EczKc-M6PJ5PmLh8gfHROUgBW0iJurD1T-5SNQWD91n-3g?e=VOgVVw",
              "_blank"
            )
          }
        >
          How to guidelines
          <SolIcon icon="arrow_right_alt" />
        </SolButton>
      </div>
    </div>
  );
};

export default AragonTutotial;
