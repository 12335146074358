import cn from "classnames";

interface BadgeProps {
  children: any;
  kind?: "default";
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({ children, kind, className }) => {
  let backgoundColor;
  switch (kind) {
    default:
      backgoundColor = "bg-jll-surface-base-secondary-alpha-1 bg-opacity-10";
  }
  return (
    <div className={cn("rounded px-3 py-1.5 w-fit", backgoundColor, className)}>
      {children}
    </div>
  );
};

export default Badge;
