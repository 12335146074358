import { ArrowBackOutlined } from "@material-ui/icons";
import {
  SolAvatar,
  SolBadge,
  SolButton,
  SolIcon,
  SolStatusIndicator,
} from "@solstice/sol-react";
import {
  SolStatusIndicatorSizeEnum,
  SolStatusIndicatorStatusEnum,
} from "@solstice/sol-stencil";
import { PUBLISH_STATUS_PUBLISHED, STATUS_ACTIVE, URLS } from "constant";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BrokerProps, ProfileProps } from "types";
import { capitalizeName, getFormatedDateTime } from "utils";
import cn from "classnames";
import { LoadingPage } from "ui-molecules";
import { Badge, StatusIndicator } from "ui-atoms";

interface IDetailLeftSide {
  broker?: BrokerProps | null;
  setBroker?: any;
  isLoading?: boolean;
  isLog?: boolean;
  isPhoto?: boolean;
}

const DetailLeftSide: React.FC<IDetailLeftSide> = ({
  broker,
  setBroker,
  isLoading,
  isLog,
  isPhoto,
}) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const navigate = useNavigate();
  const { brokerId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isFullPermission =
    !!session?.permissions?.groups?.includes("Global Admin") ||
    !!session?.is_superuser ||
    !!session?.is_staff;

  const handleButtonClick = () => {
    navigate(`/profile/${brokerId}/photo`);
  };

  return (
    <div className="min-w-[280px] h-full bg-jll-surface-base-secondary relative">
      {(isFullPermission || isLog || isPhoto) && (
        <div className="h-[68px] flex flex-row items-center pl-6 space-x-3 border-b border-b-jll-light-stroke-default">
          <button
            onClick={() =>
              isLog || isPhoto ? navigate(-1) : navigate(URLS.PROFILE.ROOT)
            }
            className="flex flex-row items-center"
          >
            <ArrowBackOutlined className="!w-5 !h-5 mr-2" />
            {isLog || isPhoto ? "Back to profile" : "Back"}
          </button>
        </div>
      )}

      {isLoading ? (
        <LoadingPage mode="back" />
      ) : (
        <>
          <div
            className={cn(
              "absolute top-0 bottom-0 py-8 space-y-8 overflow-y-auto w-full",
              {
                "mt-[68px]": isFullPermission || isLog || isPhoto,
              }
            )}
          >
            <div className="border-b border-b-jll-light-stroke-default px-10 pb-8">
              <div className="flex flex-row items-center space-x-4 mb-4">
                <div
                  className="relative w-[56px] h-full cursor-pointer rounded-full hover:shadow-gallery"
                  onClick={handleButtonClick}
                >
                  <SolAvatar
                    name={capitalizeName(
                      [broker?.first_name, broker?.last_name].join(" ")
                    )}
                    size="56"
                    image={
                      !!broker?.photos?.length
                        ? (broker?.photos?.[0] as string)
                        : ""
                    }
                  />
                  <div className="rounded-full p-0.5 flex justify-center items-center absolute -bottom-0.5 -left-0.5 bg-jll-tonal-ocean-subdued">
                    <SolIcon
                      icon="photo_camera"
                      size="16"
                      className="text-jll-tonal-ocean-strong text-bold"
                    />
                  </div>
                </div>
                <div>
                  <p>
                    {[broker?.first_name, broker?.last_name]?.join(" ") || ""}
                  </p>
                  <p className="text-jll-text-base-subdued">
                    {broker?.job_title || ""}
                  </p>
                </div>
              </div>

              <Badge>
                <div className="flex flex-row items-center space-x-3">
                  <StatusIndicator
                    status={
                      broker?.active_status === STATUS_ACTIVE
                        ? "available"
                        : "away"
                    }
                    size={12}
                  />
                  <span className="text-sm">
                    {broker?.active_status === STATUS_ACTIVE
                      ? "Broker active"
                      : "Broker inactive"}
                  </span>
                </div>
              </Badge>
            </div>

            {!isLog && !isPhoto && (
              <>
                <div className="space-y-8 border-b border-b-jll-light-stroke-default px-10 pb-8">
                  {broker?.profiles
                    ?.filter(
                      (profile: ProfileProps) =>
                        profile?.active_status === STATUS_ACTIVE
                    )
                    ?.map((profile: ProfileProps, idx) => {
                      return (
                        <div className="space-y-2" key={idx}>
                          <div
                            className={cn(
                              "flex justify-between items-center cursor-pointer font-medium",
                              {
                                "text-jll-text-base-subdued-6":
                                  searchParams?.get("tab") !==
                                  profile?.id?.toString(),
                                "text-jll-text-base-default":
                                  searchParams?.get("tab") ===
                                  profile?.id?.toString(),
                              }
                            )}
                            onClick={() => {
                              searchParams.set("tab", profile?.id?.toString());
                              if (
                                location?.pathname
                                  ?.toLowerCase()
                                  ?.includes("edit")
                              ) {
                                searchParams.set("sub", "bio");
                              }
                              setSearchParams(searchParams);
                            }}
                          >
                            <span className="text-sm">
                              {profile?.country?.name}
                            </span>
                            <SolIcon icon="keyboard_arrow_right" size="20" />
                          </div>
                          <div className="flex flex-row space-x-2 items-center">
                            <div className="w-4 h-4 rounded-full bg-white flex justify-center items-center">
                              <SolStatusIndicator
                                size={"12" as SolStatusIndicatorSizeEnum}
                                status={
                                  (profile?.publish_status ===
                                  PUBLISH_STATUS_PUBLISHED
                                    ? "available"
                                    : "busy") as SolStatusIndicatorStatusEnum
                                }
                                online
                              />
                            </div>
                            <span className="text-sm">
                              {profile?.publish_status ===
                              PUBLISH_STATUS_PUBLISHED
                                ? "Published"
                                : "Unpublished"}
                            </span>
                          </div>
                          <div className="flex flex-row space-x-1.5 items-center">
                            <SolIcon
                              icon={
                                profile?.is_compliant
                                  ? "check_circle"
                                  : "warning"
                              }
                              size="20"
                              className={cn("-ml-0.5", {
                                "text-jll-icon-rag-success-1":
                                  profile?.is_compliant,
                                "text-jll-stroke-danger":
                                  !profile?.is_compliant,
                              })}
                            />
                            <span
                              className={cn("text-sm", {
                                "text-jll-stroke-danger":
                                  !profile?.is_compliant,
                              })}
                            >
                              {profile?.is_compliant
                                ? "Profile compliant"
                                : "Profile not compliant"}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}

            <div className="px-10">
              <div className="mb-10">
                <Badge className="mb-8">
                  <div className="flex flex-row items-center space-x-3">
                    <SolIcon icon="save" size="16" />
                    <span className="text-sm">Auto-saved</span>
                  </div>
                </Badge>
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Modified by
                </p>
                <p className="text-sm mb-1">
                  {[
                    broker?.updated_user_first_name,
                    broker?.updated_user_last_name,
                  ]?.join(" ") || broker?.updated_user_email}
                </p>
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Modified on
                </p>
                <p className="text-sm mb-1">
                  {getFormatedDateTime(broker?.update_timestamp)}
                </p>
              </div>

              {!isLog && !isPhoto && (
                <SolButton
                  variant="secondary"
                  onSol-click={() =>
                    navigate(`${URLS.PROFILE.ROOT}/${broker?.id}/log`)
                  }
                >
                  <SolIcon icon="history" />
                  View activity log
                  <SolIcon icon="arrow_right_alt" />
                </SolButton>
              )}
            </div>
          </div>
        </>
      )}

      {/* <div className="absolute bottom-0 left-0 right-0 h-[116px] py-10 px-10">
        <SolButton variant="secondary">
          <SolIcon icon="call" />
          Support
        </SolButton>
      </div> */}
    </div>
  );
};

export default DetailLeftSide;
