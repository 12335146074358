import { InfoOutlined } from "@material-ui/icons";
import {
  SolButton,
  SolDropdown,
  SolIcon,
  SolTextField,
} from "@solstice/sol-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CountryModal } from "ui-molecules";
import { useContext, useEffect, useState } from "react";
import { BrokerProps, ProfileProps } from "types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { patchBrokerAPI } from "services";
import queryString from "query-string";
import { SET_ALERT_MODAL, URLS } from "constant";
import { SelectAutoComplete } from "ui-atoms";

interface IGeneral {
  broker: BrokerProps | null;
  profiles: ProfileProps[] | [];
  setBroker?: any;
  setProfiles?: any;
}

const INITIAL_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  telephone: "",
  linkedin: "",
  region: [],
  twitter: "",
  leadership: "",
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required").email("Invalid Email"),

  region: Yup.array().required("This field is required"),
});

const General: React.FC<IGeneral> = ({
  broker,
  profiles,
  setBroker,
  setProfiles,
}) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { state, dispatch } = useContext(GlobalContext);
  const { meta } = state;
  const { brokerId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [patchBroker] = useApiCall(patchBrokerAPI);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const isAdmin =
    session?.is_superuser ||
    session?.is_staff ||
    session?.permissions?.groups?.includes("Global Admin");
  const leadershipOptions = Object.keys(meta?.broker?.leadership || [])?.map(
    (key: number | string) => ({
      value: Number(key),
      label: meta?.broker?.leadership?.[key]?.value,
    })
  );

  useEffect(() => {
    if (
      !broker ||
      (broker?.pk?.toString() !== brokerId &&
        broker?.id?.toString() !== brokerId)
    )
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "region") {
        formValues = {
          ...formValues,
          [key]: (broker as any)?.[key] ? [(broker as any)?.[key]] : [],
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: (broker as any)?.[key] || "",
      };
    });
    setValues({
      ...formValues,
    });
  }, [broker]);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async () => {},
  });

  const handleTab = (
    tab: string | number,
    sub: string | number | null,
    mode = "view"
  ) => {
    let updatedFilters = { ...paramFilters };
    updatedFilters["tab"] = tab?.toString();
    if (sub) updatedFilters["sub"] = sub?.toString();
    if (mode === "view") {
      delete updatedFilters["sub"];
      navigate(
        `${URLS.PROFILE.ROOT}/${brokerId}/?${queryString.stringify(
          updatedFilters,
          {
            arrayFormat: "bracket",
          }
        )}`
      );
    } else {
      navigate(
        `${URLS.PROFILE.ROOT}/${brokerId}/edit/?${queryString.stringify(
          updatedFilters,
          {
            arrayFormat: "bracket",
          }
        )}`
      );
    }
  };

  const handleUpdate = (key: string, value: any) => {
    try {
      patchBroker({
        pk: broker?.pk || broker?.id,
        [key]: value,
      }).then((res: any) => {
        if (!res) return;
        setBroker({
          ...broker,
          [key]: value,
        });
      });
    } catch (err) {}
  };

  const onAddCountry = () => {
    if (!broker?.region) {
      dispatch({
        type: SET_ALERT_MODAL,
        payload: {
          open: true,
          kind: "warn",
          title: `Warning`,
          description: `Please select Region before adding a country profile.`,
          icon: "warning",
          btn3: null,
          btn1: {
            label: "Cancel",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                  btn3: null,
                },
              });
            },
          },
          btn2: null,
        },
      });
      return;
    }
    setIsOpen(true);
  };

  return (
    <>
      <div className="w-full px-10 pt-8">
        <div className="mb-10">
          <h2 className="text-2xl mb-1">Create New Profile</h2>
          <p className="text-sm text-jll-text-base-subdued">
            Add a new country/language profile for this real estate
            professional.
          </p>
        </div>
        <div className="flex flex-row items-center space-x-3 mb-10">
          <SolButton onSol-click={onAddCountry} variant="primary">
            Add a country
            <SolIcon icon="add" />
          </SolButton>
          <InfoOutlined className="!w-5 !h-5 text-jll-icon-base-subdued" />
        </div>
        <div className="mb-10">
          <h2 className="text-2xl mb-1">Basic info</h2>
          <p className="text-sm text-jll-text-base-subdued">
            Update name and contact information
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-row items-center grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 mb-10">
            <SolDropdown
              id="region"
              size="small"
              label="Region"
              options={Object.keys(meta?.["region"] || [])?.map(
                (key: number | string) => ({
                  value: Number(key),
                  label: meta?.["region"]?.[key],
                })
              )}
              required
              isMultiSelect={false}
              className="!mb-0"
              onUpdate-value={(e) => {
                handleUpdate("region", e?.detail);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              value={values?.region}
              errorLabel={
                touched.region ? (errors.region as string) : undefined
              }
            />
            {isAdmin && (
              <SelectAutoComplete
                name="leadership"
                label="Select Leadership type"
                className="!mb-0 -mt-2.5"
                size="sm"
                options={leadershipOptions}
                value={leadershipOptions?.find(
                  (item: any) => item?.value === values?.leadership
                )}
                onChange={(e) => {
                  setFieldValue("leadership", e?.value);
                  handleUpdate("leadership", e?.value || null);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                }}
                error={
                  touched.leadership ? (errors.leadership as string) : undefined
                }
              />
            )}
          </div>
          <div className="flex flex-row items-center mb-10 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
            <SolTextField
              label="First name"
              required
              value={values.first_name}
              onSol-input={(e: any) => setFieldValue("first_name", e?.detail)}
              id="first_name"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("first_name", values.first_name);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("first_name", values.first_name);
                }
              }}
              size="small"
              errorLabel={touched?.first_name ? errors?.first_name : ""}
            />
            <SolTextField
              label="Last name"
              required
              value={values.last_name}
              onSol-input={(e: any) => setFieldValue("last_name", e?.detail)}
              id="last_name"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("last_name", values.last_name);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("last_name", values.last_name);
                }
              }}
              size="small"
              errorLabel={touched?.last_name ? errors?.last_name : ""}
            />
          </div>

          <div className="flex flex-row items-center grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 mb-10">
            <SolTextField
              label="Email"
              value={values.email}
              onSol-input={(e: any) => setFieldValue("email", e?.detail)}
              id="email"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("email", values.email);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("email", values.email);
                }
              }}
              inputFormat="email"
              size="small"
            />
            <SolTextField
              label="Telephone"
              value={values.telephone}
              onSol-input={(e: any) => setFieldValue("telephone", e?.detail)}
              id="telephone"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("telephone", values.telephone);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("telephone", values.telephone);
                }
              }}
              inputFormat="tel"
              size="small"
              errorLabel={touched?.telephone ? errors?.telephone : ""}
            />
          </div>

          <div className="flex flex-row items-center grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 mb-10">
            <SolTextField
              label="Linkedin URL"
              value={values.linkedin}
              onSol-input={(e: any) => setFieldValue("linkedin", e?.detail)}
              id="linkedin"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("linkedin", values.linkedin);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("linkedin", values.linkedin);
                }
              }}
              size="small"
              errorLabel={touched?.linkedin ? errors?.linkedin : ""}
            />
            <SolTextField
              label="Twitter URL"
              value={values.twitter}
              onSol-input={(e: any) => setFieldValue("twitter", e?.detail)}
              id="twitter"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("twitter", values.twitter);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("twitter", values.twitter);
                }
              }}
              size="small"
              errorLabel={touched?.twitter ? errors?.twitter : ""}
            />
            <div />
          </div>
        </form>
      </div>

      <CountryModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setProfiles={setProfiles}
        profiles={profiles}
        setBroker={setBroker}
        broker={broker}
      />
    </>
  );
};

export default General;
