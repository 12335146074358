import { SolButton, SolIcon } from "@solstice/sol-react";
import { Facets, LoadingPage } from "ui-molecules";
import { useNavigate } from "react-router-dom";
import { URLS } from "constant";
import { ArrowBackOutlined } from "@material-ui/icons";

interface ListLeftSideProps {
  facets?: any;
  backUrl?: string;
  isLoading?: boolean;
  clearUrl?: string;
  backLabel?: string;
}

function ListLeftSide<T extends object>({
  facets,
  backUrl,
  isLoading,
  clearUrl = URLS.PROFILE.ROOT,
  backLabel = "Back to profile"
}: ListLeftSideProps) {
  const navigate = useNavigate();

  return (
    <div className="min-w-[280px] w-[280px] h-full relative bg-jll-surface-base-secondary overflow-y-auto">
      {!backUrl?.length ? (
        <div className="flex flex-row items-center justify-between px-10 py-6 border-b border-b-jll-stroke-default-1">
          <span>Filters</span>
          <SolButton
            variant="secondary"
            size="small"
            onSol-click={() => navigate(clearUrl)}
          >
            <span>Clear all</span>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      ) : (
        <div className="h-[68px] flex flex-row items-center pl-6 space-x-3 border-b border-b-jll-light-stroke-default">
          <button
            onClick={() => navigate(backUrl)}
            className="flex flex-row items-center"
          >
            <ArrowBackOutlined className="!w-5 !h-5 mr-2" />
            {backLabel}
          </button>
        </div>
      )}
      {isLoading ? (
        <LoadingPage mode="back" />
      ) : (
        <div className="px-10 py-8 space-y-8 w-full">
          <Facets facets={facets} />
        </div>
      )}
    </div>
  );
}

export default ListLeftSide;
