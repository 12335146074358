import { forwardRef } from "react";
import cn from "classnames";

export interface TbodyProps {
  className?: string;
  children: any;
}

const Tbody = forwardRef<any, TbodyProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <tbody className={cn("bg-white", className)} ref={ref} {...props}>
        {children}
      </tbody>
    );
  },
);

export default Tbody;
