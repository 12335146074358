import cn from "classnames";

interface StatusIndicatorProps {
  status?: "available" | "busy" | "away";
  size?: 12 | 16 | 20;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, size }) => {
  let customClass = "";
  switch (status) {
    case "available":
      customClass += " bg-jll-icon-rag-success-1";
      break;
    case "busy":
      customClass += " bg-jll-icon-rag-danger";
      break;
    case "away":
      customClass += " bg-jll-surface-rag-warning-1";
      break;
    default:
      customClass += " bg-jll-icon-rag-success-1";
  }

  switch (size) {
    case 12:
      customClass += " w-3 h-3";
      break;
    case 16:
      customClass += " w-4 h-4";
      break;
    case 20:
      customClass += " w-5 h-5";
      break;
    default:
      customClass += " w-3 h-3";
  }
  return <div className={cn("rounded", customClass)} />;
};

export default StatusIndicator;
