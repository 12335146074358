import { SolBadge, SolButton, SolIcon } from "@solstice/sol-react";
import { ProfileProps } from "types";
import { Modal } from "ui-molecules";

interface ExpertisePreviousModalProps {
  isOpen: boolean;
  setIsOpen: any;
  profile: ProfileProps | null;
}

const TAGS: { label: string; key: keyof ProfileProps }[] = [
  { label: "Services", key: "services" },
  { label: "Industry", key: "industries" },
  { label: "Property Type", key: "property_type" },
];

const ExpertisePreviousModal: React.FC<ExpertisePreviousModalProps> = ({
  isOpen,
  setIsOpen,
  profile,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="medium">
      <Modal.Header>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl">Preview</h2>
          </div>
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-4 my-4">
          <p className="">Expertise</p>
          <div className="flex flex-row items-center flex-wrap">
            {TAGS?.map(({ label, key }, idx) => {
              if (!(profile as ProfileProps)?.[key]?.length) return;
              return (
                <>
                  {profile?.[key]?.map((item: any, idx1: number) => (
                    <SolBadge
                      key={idx1}
                      size="small"
                      variant="filled"
                      className="mb-2 mr-2"
                    >
                      <span>{item?.name || item}</span>
                    </SolBadge>
                  ))}
                </>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExpertisePreviousModal;
