import axios from "../axiosConfig";
import { API_PREFIX_URL } from "constant";

export const getProfilesAPI = async () => {
  const res = await axios.get(`${API_PREFIX_URL}/profiles/`);
  return res;
};

export const getProfileAPI = async (pk: string | number) => {
  const res = await axios.get(`${API_PREFIX_URL}/profiles/${pk}`);
  return res;
};

export const postProfileAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/profiles/`, data);
  return res;
};

export const postProfileRoleAPI = async (data: any) => {
  const role = data?.role;
  delete data?.role;
  const res = await axios.post(`${API_PREFIX_URL}/profiles/${role}/`, data);
  return res;
};

export const patchProfileAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/profiles/${pk}/`, data);
  return res;
};

export const deleteProfileAPI = async (pk: number | string) => {
  const res = await axios.delete(`${API_PREFIX_URL}/profiles/${pk}/`);
  return res;
};

export const postProfilePublishAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/profiles/publish/`, data);
  return res;
}

export const deleteProfilePublishAPI = async (id: string | number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/profiles/publish/${id}`);
  return res;
}

export const postProfileGPTAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/profiles/bio-gpt/`, data);
  return res;
};

export const getPromptAPI = async (pk: string | number) => {
  const res = await axios.get(`${API_PREFIX_URL}/profiles/bio-gpt/${pk}`);
  return res;
};

export const postProfileAwardAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/profiles/awards/`, data);
  return res;
};

export const patchProfileAwardAPI = async (data: any) => {
  const id = data?.id;
  delete data?.id;
  const res = await axios.patch(`${API_PREFIX_URL}/profiles/awards/${id}/`, data);
  return res;
};

export const postProfileAragonAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/aragon/click/`, data);
  return res;
};

export const getProfileExampleAPI = async () => {
  const res = await axios.get(`${API_PREFIX_URL}/meta/profile-example/`);
  return res;
};
