import { useContext, useEffect, useRef, useState, type FC } from "react";
import cn from "classnames";
import { GlobalContext } from "context";
import { renderToString } from "react-dom/server";
import { useWindowDimensions } from "hooks";

export interface TdProps {
  className?: string;
  children?: any;
  width?: string | number;
  [key: string]: any;
}

interface ClampProps {
  line?: string;
  id: string;
  children: string | number;
}

function Td<T extends object>({ children, className, ...props }: TdProps) {
  return (
    <td
      className={cn(
        "whitespace-nowrap px-3 py-4 text-jll-text-base-default relative z-0",
        className
      )}
      {...props}
    >
      {children}
    </td>
  );
}

const Clamp: FC<ClampProps> = ({ id, line = "line-clamp-2", children }) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;
  const { width, height } = useWindowDimensions();
  const pRef = useRef<any>(null);
  const [isClamped, setIsClamped] = useState(false);

  useEffect(() => {
    if (!children) return;
    const element = pRef?.current;
    if (element) {
      setIsClamped(element?.scrollHeight > element?.clientHeight);
    }
  }, [children, width, height]);

  return (
    <p
      ref={pRef}
      className={line}
      data-tooltip-id={id}
      onMouseOver={() => isClamped && showTooltip(id)}
      onMouseLeave={() => {
        hideTooltip();
      }}
      data-tooltip-html={renderToString(
        <div className="max-w-52">{children?.toString()}</div>
      )}
    >
      {children}
    </p>
  );
};

Td.Clamp = Clamp;

export default Td;
