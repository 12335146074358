import type { FC } from "react";
import { AlertModal, LoadingModal } from "ui-molecules";

interface LayoutBaseProps {
  children: any;
}

const LayoutBase: FC<LayoutBaseProps> = ({ children }) => {
  return (
    <>
      <div className="relative w-full h-full">{children}</div>
      <AlertModal />
      <LoadingModal />
    </>
  );
};

export default LayoutBase;
