import { SolButton, SolDropdown, SolIcon } from "@solstice/sol-react";
import { SEARCH_RESULT_LIMIT, STATUS_ACTIVE } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCountryAPI, postProfileAPI } from "services";
import { BrokerProps, OptionProps } from "types";
import { SelectAutoComplete } from "ui-atoms";
import { Modal } from "ui-molecules";
import { getOptions } from "utils";
import * as Yup from "yup";

interface ICountryModal {
  isOpen: boolean;
  setIsOpen: any;
  profiles: any;
  setProfiles: any;
  broker: BrokerProps | null;
  setBroker: any;
}

const INITIAL_VALUES = {
  country: undefined,
  language: undefined,
};

const validationSchema = Yup.object().shape({
  country: Yup.object().required("This field is required"),
  language: Yup.object().required("This field is required"),
});

const CountryModal: React.FC<ICountryModal> = ({
  isOpen,
  setIsOpen,
  profiles,
  setProfiles,
  broker,
  setBroker,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const { brokerId } = useParams();
  const [postProfile] = useApiCall(postProfileAPI);
  const [getCountry] = useApiCall(getCountryAPI);
  const [languageOptions, setLanguageOptions] = useState<OptionProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    return () => {
      setValues(INITIAL_VALUES);
      setErrors({});
      setTouched({}, false);
    };
  }, [isOpen]);

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
    isValid,
    dirty,
    setErrors,
    setTouched,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      postProfile({
        broker: brokerId,
        country: (values?.country as any)?.value,
        language: (values?.language as any)?.value,
      }).then((res: any) => {
        if (!res) return;
        setProfiles([...profiles, res]);
        setBroker({ ...broker, profiles: [...profiles, res] });
        setIsOpen(false);
      }).finally(() => {
        setIsLoading(false);
      });
    },
  });

  useEffect(() => {
    if (!values?.country) {
      setLanguageOptions([]);
    } else {
      const matchOption = meta?.["country_language_map"]?.find(
        (item: any) =>
          item?.id?.toString() === (values?.country as any)?.value?.toString()
      );
      if (!!matchOption?.languages?.length) {
        const lang = matchOption?.languages?.map((item: any) => ({
          label: item?.description || item?.name,
          value: item?.id,
        }));
        setLanguageOptions(lang);
      } else {
        setLanguageOptions([{ label: "English (United States)", value: 2 }]);
      }
    }
    setFieldValue("language", "");
  }, [values?.country]);

  const loadCountryOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return getCountry({
      keyword,
      page,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      sort: "name",
      direction: "asc",
      region: [broker?.region],
    }).then((res: any) => {
      return {
        options: res?.docs?.map((country: any) => {
          return {
            value: country.pk,
            label: country?.name,
          };
        }),
        hasMore: page < res?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl">Add a country</h2>
            <p>
              Select your country, region, and language to create a profile in
              your local language to make your profile visible on other jll.com
              sites (if applicable)
            </p>
          </div>
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className="mt-6">
          <div className="mb-6">
            <SelectAutoComplete
              label="Country"
              required
              size="sm"
              loadOptions={loadCountryOptions}
              value={values?.country}
              onChange={(e) => {
                setFieldValue("country", e);
              }}
              onBlur={handleBlur}
              error={touched.country ? (errors.country as string) : undefined}
              name="country"
              key={`country_${JSON.stringify(values?.country)}`}
            />
          </div>

          <div className="mb-8">
            <SelectAutoComplete
              name="language"
              label="Language"
              required
              size="sm"
              options={languageOptions}
              value={
                languageOptions?.find(
                  (item: any) =>
                    item?.value === (values?.language as any)?.value
                ) || undefined
              }
              onChange={(e) => {
                setFieldValue("language", e);
              }}
              onBlur={handleBlur}
              error={touched.language ? (errors.language as string) : undefined}
              key={`lang_${JSON.stringify(values?.country)}`}
            />
          </div>
        </form>
        <div className="flex flex-row space-x-3 w-full justify-end mt-6">
          <SolButton
            itemType="button"
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={() => setIsOpen(false)}
          >
            Cancel
          </SolButton>
          <SolButton
            className="!w-[96px] sol-w-full"
            disabled={!isValid || !dirty || isLoading}
            onSol-click={() => handleSubmit()}
          >
            Save
          </SolButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CountryModal;
