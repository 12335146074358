import { StyledListValueProps, StyledUserValueProps } from "ui-molecules";

interface ListTableColumnProps {
  id: StyledListValueProps["valueKey"];
  label: string;
  sort?: string;
}

export const HOME_TABLE_COLUMNS: ListTableColumnProps[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "job_title",
    label: "Job Title",
  },
  {
    id: "market",
    label: "Market",
  },
  {
    id: "country",
    label: "Country",
  },
  {
    id: "language_id",
    label: "Language",
  },
];

interface TableColumnProps {
  id: string;
  label: string;
  sort?: string;
}

export const METADATA_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "count",
    label: "Number of Users",
  },
  {
    id: "updated_user",
    label: "Modified by",
  },
  {
    id: "update_timestamp",
    label: "Modified on",
  },
];

export const METADATA_MARKET_TABLE_COLUMNS: TableColumnProps[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "region",
    label: "region",
  },
  {
    id: "country_name",
    label: "country",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "count",
    label: "Number of Users",
  },
  {
    id: "updated_user",
    label: "Modified by",
  },
  {
    id: "update_timestamp",
    label: "Modified on",
  },
];

interface UserTableColumnProps {
  id: StyledUserValueProps["valueKey"];
  label: string;
  sort?: string;
}

export const USER_TABLE_COLUMNS: UserTableColumnProps[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "is_active",
    label: "Status",
  },
];

export const OFFICE_TABLE_COLUMNS = [
  {
    id: "name",
    label: "Office name",
  },
  {
    id: "language_name",
    label: "Language",
  },
  {
    id: "address",
    label: "Address",
  },
  {
    id: "phone",
    label: "Phone number",
  },
  {
    id: "updated_user",
    label: "Modified on",
  },
  {
    id: "update_timestamp",
    label: "Modified by",
  },
];

export const ACTIVITY_TABLE_COLUMNS = [
  {
    id: "broker",
    label: "Broker",
  },
  {
    id: "type_name",
    label: "Activity Type",
  },
  {
    id: "change",
    label: "Field Change",
  },
  {
    id: "updated",
    label: "Updated",
  },
];
