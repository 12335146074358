import { SolButton, SolIcon } from "@solstice/sol-react";
import { SET_LOADING_MODAL } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useParams } from "react-router-dom";
import { patchBrokerAPI, postImageAPI } from "services";
import { AIImageProps, BrokerProps, PhotoHistoryProps } from "types";
import { Toast } from "ui-atoms";
import { Table } from "ui-molecules";
import { getFormatedDateTime } from "utils";

interface ProfilePhotoProps {
  broker: BrokerProps | null;
  setBroker: any;
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ broker, setBroker }) => {
  const { brokerId } = useParams();
  const navigate = useNavigate();
  const [patchBroker] = useApiCall(patchBrokerAPI);

  const handleAragon = () => {
    navigate(`/profile/${brokerId}/photo/tutorial`);
  };

  const setPrimaryImg = async (url: string) => {
    patchBroker({
      pk: broker?.pk || broker?.id,
      photos: [url],
    }).then((res: any) => {
      if (!res) return;
      setBroker({
        ...broker,
        photos: res?.photos,
        photo_history: res?.photo_history,
      });
      Toast.success("Profile photo successfully updated!");
    });
  };

  return (
    <>
      <div className="flex flex-col w-full h-full relative overflow-auto px-10">
        <div className="flex flex-row justify-between items-center pt-8 mb-14">
          <div>
            <h3 className="text-xl mb-1">Profile image process</h3>
            <p className="text-sm text-jll-text-base-subdued">
              Choose your preferred method for a professional headshot
            </p>
          </div>
          <SolButton
            className="whitespace-nowrap"
            onSol-click={() => navigate(`/profile/${brokerId}/photo/gallery`)}
            disabled={!broker?.ai_images?.length}
          >
            <SolIcon icon="gallery_thumbnail" /> Photo gallery
          </SolButton>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 relative gap-12">
          <div className="col-span-1 bg-jll-surface-base-secondary-subdued rounded-xl flex justify-center items-center flex-col p-6 h-[330px]">
            <h3 className="text-xl mb-2">AI Headshot Generator</h3>
            <p className="text-jll-text-base-subdued text-sm text-center mb-6">
              This innovative tool generates high-quality, personalized
              headshots tailored to your preferences. Click to be redirected to
              Aragon's external site to complete the process.
            </p>
            <SolButton variant="secondary" onSol-click={handleAragon}>
              Start <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
          <div className="col-span-1 bg-jll-surface-base-secondary-subdued rounded-xl flex justify-center items-center flex-col p-6 h-[330px]">
            <h3 className="text-xl mb-2">Upload Your Own Photo</h3>
            <p className="text-jll-text-base-subdued text-sm text-center mb-6">
              You can upload your own photo that follows our best practice
              guidelines for headshots. This option allows you to use an
              existing image that meets our professional standards.
            </p>
            <SolButton
              variant="secondary"
              onSol-click={() => navigate(`/profile/${brokerId}/photo/manual`)}
            >
              Start <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
        </div>

        {!!broker?.photo_history?.length && (
          <div className="mb-[56px]">
            <div className="flex flex-col pt-8 mb-12">
              <h3 className="text-xl mb-2">Profile Photo History</h3>
              <p className="text-sm text-jll-text-base-subdued">
                Review prior photos used in the public profile
              </p>
            </div>

            <Table>
              <Table.Tbody>
                {[...broker?.photo_history]
                  ?.sort((a, b) => {
                    const dateA = new Date(a.update_timestamp);
                    const dateB = new Date(b.update_timestamp);
                    return dateB.getTime() - dateA.getTime();
                  })
                  ?.map((history: PhotoHistoryProps, idx) => (
                    <Table.Tr key={idx}>
                      <Table.Td>
                        <LazyLoadImage
                          src={history.photo}
                          className="w-[68px] min-w-[68px] h-[68px] min-h-[68px] rounded-md object-cover"
                        />
                      </Table.Td>
                      <Table.Td>
                        <p>Modified by</p>
                        <p className="text-jll-text-base-subdued">
                          {[
                            history?.updated_user_first_name,
                            history?.updated_user_last_name,
                          ]?.join(" ") || history?.updated_user_email}
                        </p>
                      </Table.Td>
                      <Table.Td>
                        <p>Modified on</p>
                        <p className="text-jll-text-base-subdued">
                          {getFormatedDateTime(history?.update_timestamp)}
                        </p>
                      </Table.Td>
                      <Table.Td className="text-right space-x-4">
                        <SolButton
                          variant="outlined"
                          onSol-click={() => setPrimaryImg(history.photo)}
                        >
                          <SolIcon icon="check" />
                          Use as Primary
                        </SolButton>
                      </Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfilePhoto;
