import axios from "axios";
import Cookies from "js-cookie";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

axios.defaults.headers.common["X-CSRFToken"] = decodeURIComponent(
  Cookies.get("csrftoken") || ""
);

let cookie = "";
if (Cookies.get("csrftoken")) {
  cookie += `csrftoken:${Cookies.get("csrftoken")}; `;
}

if (Cookies.get("sessionid")) {
  cookie += `sessionid:${Cookies.get("sessionid")}; `;
}

if (cookie) {
  axios.defaults.headers.common["Cookie"] = decodeURIComponent(cookie);
}

export default axios;
