import { SolButton } from "@solstice/sol-react";
import { useContext, useEffect, useState } from "react";
import { Modal } from "ui-molecules";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { bioValidation, ensureH2, parseOriginalJSON } from "utils";
import { GlobalContext } from "context";
import {
  EDITOR_TOOLBAR_OPTIONS,
  SET_ALERT_MODAL,
  SET_LOADING_MODAL,
} from "constant";
import { useApiCall } from "hooks";
import { patchProfileAPI } from "services";
import { BrokerProps, ProfileProps } from "types";
import cn from "classnames";
import { useSearchParams } from "react-router-dom";
import { Toast } from "ui-atoms";

interface BioTemplateModalProps {
  isOpen: boolean;
  setIsOpen: any;
  examples?: any;
  selectedIdx: number;
  profile: ProfileProps | null;
  setProfile: any;
  broker?: BrokerProps | null;
}

const BioTemplateModal: React.FC<BioTemplateModalProps> = ({
  isOpen,
  setIsOpen,
  examples,
  selectedIdx,
  profile,
  setProfile,
  broker,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [patchProfile] = useApiCall(patchProfileAPI);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedContent, setSelectedContent] = useState<any>(null);

  useEffect(() => {
    if (!isOpen || selectedIdx < 0) return;
    setSelectedIndex(selectedIdx);
  }, [selectedIdx]);

  useEffect(() => {
    if (!isOpen || selectedIndex < 0 || !examples?.length) {
      setSelectedContent(null);
      return;
    }
    const rawContentState = parseOriginalJSON(
      [...(examples || [])]?.[selectedIndex]
    );
    const contentState: any = convertFromHTML(rawContentState);
    setSelectedContent(EditorState.createWithContent(contentState));
  }, [selectedIndex]);

  const handleUpdate = () => {
    try {
      const contentState = selectedContent?.getCurrentContent();
      const rawContentState =
        broker?.leadership === 1
          ? ensureH2(convertToHTML(contentState) as any, [
              "current responsibilities",
            ])
          : ensureH2(convertToHTML(contentState) as any, [
              "current responsibilities",
              "experience",
            ]);
      // const rawContentState = convertToHTML(contentState);
      const contentString = JSON.stringify(rawContentState);
      const value = contentString;

      const action = () => {
        try {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: true,
              title: "Saving bio content",
            },
          });
          patchProfile({
            pk: profile?.id,
            bio: value,
          })
            .then((res: any) => {
              if (!res) return;
              setProfile({
                ...profile,
                bio: value,
              });
            })
            .finally(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  title: "",
                },
              });
              setIsOpen(false);
              searchParams.set("mode", "general");
              setSearchParams(searchParams);
            });
        } catch (err) {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
        }
      };

      const validate = bioValidation(
        JSON.parse(value),
        broker?.leadership !== 1
      );
      if (!!validate?.length) {
        dispatch({
          type: SET_ALERT_MODAL,
          payload: {
            open: true,
            kind: "error",
            title: `Bio format warning`,
            description: validate,
            icon: "warning",
            btn3: null,
            btn1: {
              label: "Cancel",
              onClick: () => {
                dispatch({
                  type: SET_ALERT_MODAL,
                  payload: {
                    open: false,
                    kind: "",
                    title: "",
                    description: "",
                    icon: "",
                    btn1: null,
                    btn2: null,
                    btn3: null,
                  },
                });
              },
            },
            btn2: {
              label: "Save Anyway",
              onClick: () => {
                dispatch({
                  type: SET_ALERT_MODAL,
                  payload: {
                    open: false,
                    kind: "",
                    title: "",
                    description: "",
                    icon: "",
                    btn1: null,
                    btn2: null,
                    btn3: null,
                  },
                });
                action();
              },
            },
          },
        });
        return;
      }
      action();
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="extra">
      <Modal.Header>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl mb-2">Bio examples</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              efficitur elementum erat, vel interdum ipsum mollis id. Maecenas
              sollicitudin nunc in accumsan posuere.
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="flex flex-row items-center space-x-4 my-6">
            {examples?.map((item: any, idx: number) => (
              <SolButton
                variant="outlined"
                key={idx}
                onSol-click={() => setSelectedIndex(idx)}
                className={cn({
                  "border border-jll-stroke-interaction rounded p-px":
                    idx === selectedIndex,
                })}
              >
                Example {idx + 1}
              </SolButton>
            ))}
          </div>

          <div className="border border-jll-light-stroke-default rounded mb-6">
            <Editor
              editorState={selectedContent as any}
              toolbarClassName="hidden"
              wrapperClassName="border-none"
              editorClassName="px-5"
              onEditorStateChange={(e) => {
                setSelectedContent(e);
              }}
              toolbar={EDITOR_TOOLBAR_OPTIONS}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row items-center justify-end space-x-4">
          <SolButton
            variant="secondary"
            onSol-click={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </SolButton>
          <SolButton onSol-click={handleUpdate}>Add to your bio</SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BioTemplateModal;
