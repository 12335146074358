import { GlobalContext } from "context";
import { useContext } from "react";
import { renderToString } from "react-dom/server";
import { ActivityProps } from "types";
import { joinArray, pickSpecificKeyFromObj } from "utils";
import cn from "classnames";

interface IActivityContent {
  activity?: ActivityProps;
}

const Content = ({ numChangedFields, fieldList, isTooltip }: any) => {
  return (
    <>
      <span
        className={cn("mr-1 text-sm", {
          "text-jll-text-base-subdued": !isTooltip,
        })}
      >
        {numChangedFields} field{numChangedFields !== 1 ? "s" : ""} changed :
      </span>
      <span
        className={cn("capitalize", {
          "max-w-[200px] whitespace-normal flex": isTooltip,
        })}
      >
        {fieldList.replaceAll("_", " ")}
      </span>
    </>
  );
};

const ActivityContent: React.FC<IActivityContent> = ({ activity }) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;

  if (!activity) return <>-</>;

  const changedContent = pickSpecificKeyFromObj(activity, "change__");
  if (!changedContent || !Object.keys(changedContent)?.length) {
    return <>-</>;
  }
  const changedFields = Object.keys(changedContent)
    .filter((field) => field.startsWith("change__"))
    .map((field) => field.slice(8));
  const numChangedFields = changedFields.length;
  const fieldList = joinArray(changedFields, ", ");

  return (
    <div
      className="whitespace-normal line-clamp-2"
      data-tooltip-id={`activity-content-${activity?.pk}`}
      onMouseOver={() => showTooltip(`activity-content-${activity?.pk}`)}
      onMouseLeave={() => hideTooltip()}
      data-tooltip-html={renderToString(
        <Content
          numChangedFields={numChangedFields}
          fieldList={fieldList}
          isTooltip
        />
      )}
    >
      <Content numChangedFields={numChangedFields} fieldList={fieldList} />
    </div>
  );
};

export default ActivityContent;
