import React, { useContext, useEffect, useMemo, useState } from "react";
import { SolButton } from "@solstice/sol-react";
import debounce from "lodash.debounce";
import { LayoutAuthHome } from "ui-organisms";
import { Input, SelectAutoComplete } from "ui-atoms";
import { SearchOutlined } from "@material-ui/icons";
import { ListLeftSide, Loading, StyledListValue, Table } from "ui-molecules";
import {
  HOME_TABLE_COLUMNS,
  SEARCH_RESULT_LIMIT,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import { AddProfileModal } from "ui-molecules";
import { useApiCall } from "hooks";
import { getBrokerListAPI, getCountryAPI, getMarketAPI } from "services";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { BrokerProps, DirectionEnum } from "types";
import { getDecimalFormating, joinArray } from "utils";
import { GlobalContext } from "context";

const Homepage: React.FC = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const location = useLocation();
  const navigate = useNavigate();
  const [getBrokerList, isLoading] = useApiCall(getBrokerListAPI);
  const [getCountry] = useApiCall(getCountryAPI);
  const [getMarket] = useApiCall(getMarketAPI);
  const [data, setData] = useState<BrokerProps[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [facets, setFacets] = useState<any>();
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [keyword, setKeyword] = useState<string>("");
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<any>({});

  const regionOptions = Object.keys(meta?.["region"] || [])?.map(
    (key: number | string) => ({
      value: Number(key),
      label: meta?.["region"]?.[key],
    })
  );

  useEffect(() => {
    let updatedFilters = {
      ...paramFilters,
      market: [],
      country: [],
    };

    if (
      session?.permissions?.groups?.includes("Country Admin") &&
      !session?.permissions?.groups?.includes("Global Admin")
    ) {
      updatedFilters = {
        ...updatedFilters,
        country:
          session?.permissions?.countries?.map((country: any) =>
            typeof country === "object" ? country?.id : country
          ) || [],
      };
    } else if (
      session?.permissions?.groups?.includes("Local Admin") &&
      !session?.permissions?.groups?.includes("Global Admin")
    ) {
      updatedFilters = {
        ...updatedFilters,
        market:
          session?.permissions?.markets?.map((market: any) =>
            typeof market === "object" ? market?.id : market
          ) || [],
      };
    }

    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
    setIsStarted(true);
  }, []);

  useEffect(() => {
    if (!isStarted) return;
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters({ ...filters });
  }, [location, isStarted]);

  useEffect(() => {
    setPage(1);
    if (!paramFilters) return;
    setIsFirstLoading(true);
    getBrokerList({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      sort: "update_timestamp",
      direction: "desc",
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
        setFacets(res?.facets);
        setSortFields(res?.sort_fields);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      })
      ?.finally(() => {
        setIsFirstLoading(false);
      });
  }, [paramFilters]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getBrokerList({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      sort: "update_timestamp",
      direction: "desc",
      ...paramFilters,
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
      setFacets(res?.facets);
      setSortFields(res?.sort_fields);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const handleFilter = (e: any, key: string) => {
    const updatedFilters = { ...paramFilters };
    if (!e?.length) {
      delete updatedFilters[key];
      setSelectedOptions((prev: any) => ({ ...prev, [key]: [] }));
    } else {
      const values = e?.map((item: any) => item?.value);
      updatedFilters[key] = values;
      setSelectedOptions((prev: any) => ({ ...prev, [key]: e }));
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const loadCountryOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return Promise.all([
      paramFilters?.country &&
        getCountry({
          ids: paramFilters?.country || [],
          active_status: [STATUS_ACTIVE],
          sort: "name",
          direction: "asc",
        }),
      getCountry({
        keyword,
        page,
        limit: SEARCH_RESULT_LIMIT,
        region: paramFilters?.["region"] || [],
        active_status: [STATUS_ACTIVE],
        sort: "name",
        direction: "asc",
      }),
    ]).then((res_list) => {
      const selected = (res_list?.[0]?.docs || [])?.map((country: any) => {
        return {
          value: country.pk,
          label: country?.name,
        };
      });
      setSelectedOptions((prev: any) => ({ ...prev, country: selected }));
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...(res_list?.[1]?.docs || []).filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return {
        options: combinedArray?.map((country: any) => {
          return {
            value: country.pk,
            label: country?.name,
          };
        }),
        hasMore: page < (res_list?.[1] || res_list?.[0])?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  const loadMarketOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return Promise.all([
      paramFilters?.market &&
        getMarket({
          ids: paramFilters?.market || [],
          active_status: [STATUS_ACTIVE],
          sort: "name",
          direction: "asc",
        }),
      getMarket({
        keyword,
        page,
        limit: SEARCH_RESULT_LIMIT,
        region: paramFilters?.["region"] || [],
        country: paramFilters?.["country"] || [],
        active_status: [STATUS_ACTIVE],
        sort: "name",
        direction: "asc",
      }),
    ]).then((res_list) => {
      const selected = (res_list?.[0]?.docs || [])?.map((market: any) => {
        return {
          value: market.pk,
          label: market?.name,
        };
      });
      setSelectedOptions((prev: any) => ({ ...prev, market: selected }));
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return {
        options: combinedArray?.map((market: any) => {
          return {
            value: market.pk,
            label: joinArray([market?.country_name, market?.name], " | "),
          };
        }),
        hasMore: page < (res_list?.[1] || res_list?.[0])?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  return (
    <>
      <LayoutAuthHome>
        <ListLeftSide facets={facets} />
        <div className="flex flex-col w-full h-full relative overflow-hidden">
          {/* Filter */}
          <div className="flex flex-row items-center pt-6 pb-8 mx-10 border-b border-b-jll-stroke-subdued justify-between flex-wrap">
            <div className="flex flex-row items-center space-x-3">
              <SelectAutoComplete
                placeholder="Region"
                className="!mb-0 min-w-[200px] w-full"
                label="Region"
                size="sm"
                isMulti
                options={regionOptions}
                name="region"
                value={regionOptions?.filter((item: any) =>
                  paramFilters?.["region"]?.includes(item?.value?.toString())
                )}
                onChange={(e) => {
                  handleFilter(e, "region");
                }}
              />
              {paramFilters && (
                <SelectAutoComplete
                  placeholder="Country"
                  className="!mb-0 min-w-[200px] w-full"
                  label="Country"
                  size="sm"
                  isMulti
                  loadOptions={loadCountryOptions}
                  name="country"
                  value={selectedOptions?.country || []}
                  onChange={(e: any) => {
                    handleFilter(e, "country");
                  }}
                  key={[...(paramFilters?.["region"] || [])]?.toString()}
                />
              )}

              {paramFilters && (
                <SelectAutoComplete
                  placeholder="Market"
                  className="!mb-0 min-w-[200px] w-full"
                  label="Market"
                  size="sm"
                  isMulti
                  loadOptions={loadMarketOptions}
                  name="market"
                  value={selectedOptions?.market || []}
                  onChange={(e) => {
                    handleFilter(e, "market");
                  }}
                  key={[
                    ...(paramFilters?.["region"] || []),
                    ...(paramFilters?.["country"] || [100]),
                  ]?.toString()}
                />
              )}

              {paramFilters && (
                <SelectAutoComplete
                  name="leadership"
                  label="Leadership"
                  size="sm"
                  isMulti
                  className="!mb-0 min-w-[200px]"
                  options={Object.keys(meta?.broker?.leadership || [])?.map(
                    (key: number | string) => ({
                      value: Number(key),
                      label: meta?.broker?.leadership?.[key]?.value,
                    })
                  )}
                  value={selectedOptions?.leadership || []}
                  onChange={(e) => {
                    handleFilter(e, "leadership");
                  }}
                />
              )}

              <div className="mt-8">
                <SolButton
                  variant="tertiary"
                  className="whitespace-nowrap text-jll-text-base-subdued"
                  onSol-click={() => {
                    navigate(URLS.PROFILE.ROOT);
                    setSelectedOptions({});
                  }}
                >
                  Clear filters
                </SolButton>
              </div>
            </div>
            <div>
              <Input
                leadingIcon={SearchOutlined}
                isClearable
                onChange={onChangeInput}
                value={keyword}
                className="w-[280px] !mb-0"
                placeholder="Search by name, country, city..."
                label="Search"
              />
            </div>
          </div>

          {!!data?.length && (
            <div className="flex flex-col mx-10 relative">
              <div className="flex flex-row items-center justify-between space-x-4 py-3 border-b border-b-jll-stroke-subdued">
                <span>{`${getDecimalFormating(total)} result${
                  total > 1 ? "s" : ""
                }`}</span>
              </div>
            </div>
          )}

          <div className="flex flex-1 w-full h-full flex-col px-10 overflow-auto">
            {isFirstLoading ? (
              <Table.Loading size={4} />
            ) : (
              <>
                {!!data?.length ? (
                  <>
                    <Table>
                      <Table.Thead>
                        <Table.Tr>
                          {HOME_TABLE_COLUMNS?.map((column, idx) => (
                            <Table.Th
                              key={idx}
                              filterId={column.id}
                              sortFields={sortFields}
                              filters={paramFilters}
                              onClick={onClickSorting}
                            >
                              {column?.label}
                            </Table.Th>
                          ))}
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {data?.map((item: any, idx: number) => (
                          <Table.Tr
                            key={idx}
                            onClick={(e: any) => {
                              if (e?.metaKey || e?.ctrlKey) {
                                window.open(
                                  `${URLS.PROFILE.ROOT}/${item?.pk}/edit?tab=general`
                                );
                              } else
                                navigate(
                                  `${URLS.PROFILE.ROOT}/${item?.pk}/edit?tab=general`
                                );
                            }}
                          >
                            {HOME_TABLE_COLUMNS?.map((column, idx1) => (
                              <Table.Td
                                key={idx1}
                                className="!whitespace-normal"
                              >
                                <StyledListValue
                                  broker={item}
                                  valueKey={column.id}
                                />
                              </Table.Td>
                            ))}
                          </Table.Tr>
                        ))}
                      </Table.Tbody>
                    </Table>
                    {!!(total > data?.length) && (
                      <div
                        className="w-full py-2 flex justify-center items-center"
                        ref={sentryRef}
                      >
                        <Loading />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-full h-full flex justify-center items-center text-jll-text-base-subdued">
                    There is not any profile data
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </LayoutAuthHome>

      <AddProfileModal />
    </>
  );
};

export default React.memo(Homepage);
