import { SolButton, SolIcon } from "@solstice/sol-react";
import {
  AlertModal,
  DetailLeftSide,
  LoadingPage,
  PublishModal,
} from "ui-molecules";
import { LayoutDetail } from "ui-organisms";
import cn from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import General from "./General";
import Geography from "./Geography";
import License from "./License";
import Market from "./Market";
import Preview from "./Preview";
import Bio from "./Bio";
import { useApiCall } from "hooks";
import {
  deleteProfileAPI,
  deleteProfilePublishAPI,
  getBrokerAPI,
  getProfileAPI,
  logoutApi,
  postProfilePublishAPI,
} from "services";
import { BrokerProps, ProfileProps } from "types";
import { GlobalContext } from "context";
import {
  PUBLISH_STATUS_PUBLISHED,
  PUBLISH_STATUS_REMOVED,
  SET_ALERT_MODAL,
  SET_LOADING_MODAL,
  STATUS_ACTIVE,
  STATUS_REMOVED,
  URLS,
} from "constant";
import Awards from "./Awards";
import Leader from "./Leader";
import {
  AIPhotoGallery,
  AragonTutotial,
  ManualPhoto,
  ProfilePhoto,
} from "./Photo";
import { Tooltip } from "react-tooltip";
import { renderToString } from "react-dom/server";

const SUB_TABS = [
  { label: "Bio", value: "bio" },
  { label: "Geography", value: "geography" },
  { label: "Role", value: "markets" },
  { label: "Awards", value: "awards" },

  // { label: "License", value: "license" }
];

const ORDER = ["bio", "geography", "markets", "awards", "license", "leader"];

const Profile = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { brokerId, logId } = useParams();
  const bioRef = useRef<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [getBroker, isLoading] = useApiCall(getBrokerAPI);
  const [logout] = useApiCall(logoutApi);
  const [getProfile, isProfileLoading] = useApiCall(getProfileAPI);
  const [postProfilePublish] = useApiCall(postProfilePublishAPI);
  const [deleteProfilePublish] = useApiCall(deleteProfilePublishAPI);
  const [deleteProfile] = useApiCall(deleteProfileAPI);
  const [activeTab, setActiveTab] = useState<string | number>("general");
  const [subActiveTab, setSubActiveTab] = useState<string>("bio");
  const [isPublish, setIsPublish] = useState(false);
  const [broker, setBroker] = useState<BrokerProps | null>(null);
  const [profiles, setProfiles] = useState<ProfileProps[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<ProfileProps | any>(
    null
  );
  const [subTabs, setSubTabs] = useState(SUB_TABS);

  const isEdit = location?.pathname?.toLowerCase()?.includes("edit");
  // const isLog = location?.pathname?.toLowerCase()?.includes("log");
  const isPhoto = location?.pathname?.toLowerCase()?.includes("photo");

  let isPhotoTutorial,
    isGallery,
    isManual = false;
  if (isPhoto) {
    if (location?.pathname?.toLowerCase()?.includes("tutorial")) {
      isPhotoTutorial = true;
    }
    if (location?.pathname?.toLowerCase()?.includes("gallery")) {
      isGallery = true;
    }
    if (location?.pathname?.toLowerCase()?.includes("manual")) {
      isManual = true;
    }
  }

  useEffect(() => {
    if (!session) return;
    if (
      !session?.permissions?.groups?.length &&
      !session?.is_superuser &&
      !session?.is_staff
    ) {
      if (!session?.permissions?.broker_id) {
        // logout()
        //   .then((data: any) => {
        //     window.location.href = data?.redirect;
        //   })
        //   .finally(() => {
        //     sessionStorage.clear();
        //     return navigate(`/`);
        //   });
        return navigate(`/permission`);
      }
      if (session?.permissions?.broker_id !== brokerId)
        return navigate(`/profile/${session?.permissions?.broker_id}`);
    }
  }, []);

  useEffect(() => {
    if (!brokerId || brokerId === "undefined") {
      setBroker(null);
      return;
    }
    getBroker(brokerId)?.then((res: any) => {
      if (!res) return;
      const prof = res?.profiles?.filter(
        (profile: ProfileProps) => profile?.active_status === STATUS_ACTIVE
      );
      setBroker(res);
      if (!prof?.length) {
        setSubActiveTab("");
        navigate(`${URLS.PROFILE.ROOT}/${brokerId}/edit?tab=general`);
        return;
      }
      if (
        (!searchParams?.get("tab") ||
          searchParams?.get("tab")?.toLowerCase() === "general") &&
        !!prof?.length &&
        !isEdit
      ) {
        searchParams?.delete("mode");
        searchParams?.delete("active");
        searchParams.set("tab", prof?.[0]?.id);
        setSearchParams(searchParams);
      }
    });
  }, [brokerId]);

  useEffect(() => {
    if (
      searchParams?.get("tab")?.toLowerCase() !== "general" &&
      !profiles?.length
    )
      return;
    if (searchParams?.get("tab") && searchParams?.get("tab") !== activeTab) {
      setActiveTab((searchParams?.get("tab") || "")?.toLowerCase());
      const pro = profiles?.find(
        (item) => item?.id?.toString() === searchParams?.get("tab")
      );
      if (pro?.id) {
        getProfile(pro?.id).then((res: any) => {
          if (!res) {
            setSelectedProfile(null);
            return;
          }
          if (!subTabs?.find((tab) => tab?.value === "license")) {
            setSubTabs((prev) => [
              ...prev,
              {
                label: "License",
                value: "license",
                disabled: !!res?.licenses?.length ? true : false,
              },
            ]);
          }
          setSelectedProfile(res);
        });
      }
    } else if (!searchParams?.get("tab")) {
      setActiveTab("general");
    }

    if (searchParams?.get("sub")) {
      setSubActiveTab((searchParams?.get("sub") || "")?.toLowerCase());
    } else {
      setSubActiveTab("");
    }
  }, [searchParams, profiles]);

  useEffect(() => {
    const prof = broker?.profiles?.filter(
      (profile: ProfileProps) => profile?.active_status === STATUS_ACTIVE
    );
    setProfiles(prof || []);
  }, [broker?.profiles]);

  const handleTab = (tab: string | number) => {
    searchParams.set("tab", tab?.toString());
    if (tab?.toString()?.toLowerCase() === "general") {
      searchParams.delete("sub");
    } else {
      isEdit && searchParams.set("sub", "bio");
    }
    searchParams?.delete("mode");
    searchParams?.delete("active");
    setSearchParams(searchParams);
  };

  const handleSubTab = (tab: string) => {
    searchParams.set("sub", tab?.toString());
    searchParams?.delete("mode");
    searchParams?.delete("active");
    setSearchParams(searchParams);
  };

  const handleConfirmPublish = () => {
    dispatch({
      type: SET_ALERT_MODAL,
      payload: {
        open: true,
        kind: "warn",
        title: `Publish to Public Websites`,
        description:
          "By proceeding, you agree to publish this content to multiple JLL web properties.",
        icon: "warning",
        btn3: {
          label: "Unpublish",
          onClick: () => {
            dispatch({
              type: SET_ALERT_MODAL,
              payload: {
                open: false,
                kind: "",
                title: "",
                description: "",
                icon: "",
                btn1: null,
                btn2: null,
                btn3: null,
              },
            });
            handleUnpublish();
          },
        },
        btn1: {
          label: "Cancel",
          onClick: () => {
            dispatch({
              type: SET_ALERT_MODAL,
              payload: {
                open: false,
                kind: "",
                title: "",
                description: "",
                icon: "",
                btn1: null,
                btn2: null,
                btn3: null,
              },
            });
          },
        },
        btn2: {
          label: "Publish",
          onClick: () => {
            dispatch({
              type: SET_ALERT_MODAL,
              payload: {
                open: false,
                kind: "",
                title: "",
                description: "",
                icon: "",
                btn1: null,
                btn2: null,
                btn3: null,
              },
            });
            handlePublish();
          },
        },
      },
    });
  };

  const handlePublish = () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Publishing Profile...",
        },
      });
      postProfilePublish({
        broker_profile: selectedProfile?.id,
      })
        .then((res: any) => {
          if (!res) return;
          setSelectedProfile({
            ...selectedProfile,
            publish_status: res?.publish_status,
          });
          const index: number = (broker?.profiles || [])?.findIndex(
            (profile) => profile?.id === selectedProfile?.id
          );
          if (index > -1) {
            let newBroker: any = { ...broker };
            newBroker.profiles[index] = newBroker.profiles[index] = {
              ...newBroker.profiles[index],
              publish_status: res?.publish_status,
            };
            setBroker(newBroker);
          }
        })
        ?.finally(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
        });
    } catch (err) {}
  };

  const handleUnpublish = () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Unpublishing Profile...",
        },
      });
      deleteProfilePublish(selectedProfile?.id)
        .then((res: any) => {
          if (!res?.status) return;
          setSelectedProfile({
            ...selectedProfile,
            publish_status: PUBLISH_STATUS_REMOVED,
          });
          const index: number = (broker?.profiles || [])?.findIndex(
            (profile) => profile?.id === selectedProfile?.id
          );
          if (index > -1) {
            let newBroker: any = { ...broker };
            newBroker.profiles[index] = {
              ...newBroker.profiles[index],
              publish_status: PUBLISH_STATUS_REMOVED,
            };
            setBroker(newBroker);
          }
        })
        ?.finally(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
        });
    } catch (err) {}
  };

  const handleDelete = () => {
    try {
      const action = () => {
        try {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: true,
              title: "Deleting Profile...",
            },
          });
          deleteProfile(selectedProfile?.id)
            ?.then((res: any) => {
              if (!res?.status) return;
              const index: number = (broker?.profiles || [])?.findIndex(
                (profile) => profile?.id === selectedProfile?.id
              );
              if (index > -1) {
                let newBroker: any = { ...broker };
                newBroker.profiles[index] = {
                  ...newBroker.profiles[index],
                  active_status: STATUS_REMOVED,
                };
                setBroker(newBroker);
                const prof = newBroker?.profiles?.filter(
                  (profile: ProfileProps) =>
                    profile?.active_status === STATUS_ACTIVE
                );
                setProfiles(prof);
              }
              setSelectedProfile(null);
              searchParams.set("tab", "general");
              searchParams?.delete("sub");
              searchParams?.delete("active");
              searchParams?.delete("mode");
              setSearchParams(searchParams);
            })
            ?.finally(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  title: "",
                },
              });
            });
        } catch (err) {}
      }

      dispatch({
        type: SET_ALERT_MODAL,
        payload: {
          open: true,
          kind: "error",
          title: `Delete Bio`,
          description: `By proceeding, you will permanently delete this bio.`,
          icon: "warning",
          btn3: null,
          btn1: {
            label: "Cancel",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                  btn3: null,
                },
              });
            },
          },
          btn2: {
            label: "Delete",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                  btn3: null,
                },
              });
              action();
            },
          },
        },
      });
    } catch (err) {}
  };

  return (
    <>
      <LayoutDetail broker={broker}>
        <DetailLeftSide
          broker={broker}
          setBroker={setBroker}
          isLoading={isLoading}
          isPhoto={isPhoto}
        />
        <div className="w-full h-full relative flex flex-col">
          {/* Header */}
          {!isPhoto && (
            <>
              {isLoading ? (
                <LoadingPage rows={12} className="!pt-16" />
              ) : (
                <>
                  <div className="min-h-[68px] flex flex-row items-center px-10 justify-between border-b border-b-jll-stroke-subdued">
                    <div className="flex flex-row items-center justify-between w-full h-full space-x-6">
                      <div className="flex flex-row items-center h-full space-x-6">
                        {isEdit && (
                          <div
                            className={cn(
                              "h-full space-x-2 cursor-pointer flex items-center",
                              {
                                "border-b border-b-jll-text-base-default":
                                  activeTab === "general",
                              }
                            )}
                            onClick={() => handleTab("general")}
                          >
                            <span
                              className={cn("text-jll-icon-base-subdued", {
                                "text-jll-text-base-default":
                                  activeTab === "general",
                              })}
                            >
                              Profile info
                            </span>
                          </div>
                        )}
                        {!!profiles?.length &&
                          profiles?.map((profile, idx: number) => (
                            <div
                              key={idx}
                              className={cn(
                                "h-full space-x-2 cursor-pointer flex items-center",
                                {
                                  "border-b border-b-jll-text-base-default":
                                    activeTab?.toString() ===
                                    profile?.id?.toString(),
                                }
                              )}
                              onClick={() => {
                                handleTab(profile?.id);
                              }}
                            >
                              <span
                                className={cn("text-jll-icon-base-subdued", {
                                  "text-jll-text-base-default":
                                    activeTab?.toString() ===
                                    profile?.id?.toString(),
                                })}
                              >
                                Bio and details - {profile?.country?.name} (
                                {profile?.language_name})
                              </span>
                            </div>
                          ))}
                      </div>

                      {!isEdit ? (
                        <SolButton
                          variant="secondary"
                          onSol-click={() => {
                            navigate(
                              `${URLS.PROFILE.ROOT}/${brokerId}/edit${location?.search}&sub=bio`
                            );
                          }}
                        >
                          Edit profile
                          <SolIcon icon="edit" />
                        </SolButton>
                      ) : (
                        <>
                          {activeTab && activeTab !== "general" ? (
                            <>
                              <div className="flex flex-row items-center space-x-4">
                                <SolButton
                                  variant="secondary"
                                  data-tooltip-id="preview_tooltip"
                                  data-tooltip-content="Review profile card"
                                  onSol-click={() => {
                                    navigate(
                                      `${
                                        URLS.PROFILE.ROOT
                                      }/${brokerId}?tab=${searchParams?.get(
                                        "tab"
                                      )}`
                                    );
                                  }}
                                >
                                  Preview
                                  <SolIcon icon="preview" />
                                </SolButton>
                                <Tooltip id="preview_tooltip" />
                                <SolButton
                                  variant={
                                    selectedProfile?.publish_status ===
                                    PUBLISH_STATUS_PUBLISHED
                                      ? "primary"
                                      : "secondary"
                                  }
                                  data-tooltip-id="publish_tooltip"
                                  data-tooltip-content="Publish this profile for fast updates to websites"
                                  onSol-click={handleConfirmPublish}
                                >
                                  {selectedProfile?.publish_status ===
                                  PUBLISH_STATUS_PUBLISHED
                                    ? "Republish"
                                    : "Publish"}
                                  <SolIcon icon="public" />
                                </SolButton>
                                <Tooltip id="publish_tooltip" />
                                {selectedProfile?.publish_status ===
                                  PUBLISH_STATUS_PUBLISHED && (
                                  <>
                                    <SolButton
                                      variant="primary"
                                      data-tooltip-id="unpublish_tooltip"
                                      data-tooltip-content="Remove this profile from the websites"
                                      onSol-click={handleUnpublish}
                                    >
                                      Unpublish
                                      <SolIcon icon="public_off" />
                                    </SolButton>
                                    <Tooltip id="unpublish_tooltip" />
                                  </>
                                )}
                                <SolButton
                                  variant="secondary"
                                  data-tooltip-id="delete_tooltip"
                                  data-tooltip-content="Delete this bio"
                                  onSol-click={handleDelete}
                                >
                                  <SolIcon icon="delete" />
                                </SolButton>
                                <Tooltip id="delete_tooltip" />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                    {/* <div className="flex flex-row items-center space-x-6">
                  <div className="flex flex-row items-center space-x-2">
                    <SolAvatar name="RM" status="online" size="24" />
                    <span className="text-sm">Ryong Ma</span>
                  </div>
                </div> */}
                  </div>
                  {isEdit && !!subActiveTab?.length && (
                    <div className="min-h-[68px] flex flex-row items-center px-10 border-b border-b-jll-stroke-subdued justify-between">
                      <div className="flex flex-row items-center h-full space-x-6">
                        {subTabs
                          ?.sort(
                            (a, b) =>
                              ORDER.indexOf(a.value) - ORDER.indexOf(b.value)
                          )
                          ?.map((tab, idx) => (
                            <div
                              key={idx}
                              className={cn(
                                "h-full space-x-2 cursor-pointer flex items-center",
                                {
                                  "border-b border-b-jll-text-base-default":
                                    subActiveTab === tab.value,
                                  "!cursor-not-allowed": (tab as any)?.disabled,
                                }
                              )}
                              data-tooltip-id={`${
                                (tab as any)?.disabled &&
                                tab?.value === "license" &&
                                "license_tab"
                              }`}
                              data-tooltip-html={renderToString(
                                <p>
                                  If license information exists in WorkDay, it
                                  will sync automatically to Profile Management.
                                  <br />
                                  Please visit WorkDay to update license
                                  information.
                                </p>
                              )}
                              onClick={() =>
                                !(tab as any)?.disabled &&
                                handleSubTab(tab.value)
                              }
                            >
                              <span
                                className={cn("text-jll-icon-base-subdued", {
                                  "text-jll-text-base-default":
                                    subActiveTab === tab.value,
                                })}
                              >
                                {tab.label}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col h-full relative">
                    <div className="absolute top-0 right-0 left-0 bottom-0 overflow-y-auto">
                      {!isEdit ? (
                        <Preview
                          profile={selectedProfile}
                          broker={broker}
                          isLoading={isProfileLoading}
                        />
                      ) : (
                        <>
                          {activeTab === "general" && (
                            <General
                              broker={broker}
                              profiles={profiles}
                              setBroker={setBroker}
                              setProfiles={setProfiles}
                            />
                          )}
                          {subActiveTab === "awards" && !!selectedProfile && (
                            <Awards
                              profile={selectedProfile}
                              setProfile={setSelectedProfile}
                              broker={broker}
                              isLoading={isProfileLoading}
                              handlePublish={handleConfirmPublish}
                            />
                          )}
                          {subActiveTab === "geography" &&
                            !!selectedProfile && (
                              <Geography
                                profile={selectedProfile}
                                setProfile={setSelectedProfile}
                                isLoading={isProfileLoading}
                                broker={broker}
                                setBroker={setBroker}
                              />
                            )}
                          {subActiveTab === "license" && !!selectedProfile && (
                            <License
                              profile={selectedProfile}
                              setProfile={setSelectedProfile}
                              isLoading={isProfileLoading}
                              broker={broker}
                              handlePublish={handleConfirmPublish}
                            />
                          )}
                          {subActiveTab === "bio" && !!selectedProfile && (
                            <Bio
                              profile={selectedProfile}
                              setProfile={setSelectedProfile}
                              isLoading={isProfileLoading}
                              broker={broker}
                              ref={bioRef}
                            />
                          )}
                          {subActiveTab === "markets" && (
                            <Market
                              profile={selectedProfile}
                              setProfile={setSelectedProfile}
                              isLoading={isProfileLoading}
                              broker={broker}
                            />
                          )}
                          {subActiveTab === "preview" && (
                            <Preview
                              profile={selectedProfile}
                              broker={broker}
                            />
                          )}
                          {subActiveTab === "leader" && (
                            <Leader
                              profile={selectedProfile}
                              setProfile={setSelectedProfile}
                              isLoading={isProfileLoading}
                              broker={broker}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {isPhoto &&
            (isPhotoTutorial ? (
              <AragonTutotial broker={broker} />
            ) : isGallery ? (
              <AIPhotoGallery broker={broker} setBroker={setBroker} />
            ) : isManual ? (
              <ManualPhoto broker={broker} setBroker={setBroker} />
            ) : (
              <ProfilePhoto broker={broker} setBroker={setBroker} />
            ))}
        </div>
      </LayoutDetail>
      <PublishModal isOpen={isPublish} setIsOpen={setIsPublish} />
      <AlertModal />
      <Tooltip id="license_tab" />
    </>
  );
};

export default Profile;
